import React, { useContext, useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { useParams } from "react-router-dom";
import { UrlContext } from "../../context/UrlProvider";
import { FileUploader } from "react-drag-drop-files";

function SingleTask({ tasks, resources, setResources, currentUser }) {
  const apiUrl = useContext(UrlContext);

  const [content, setContent] = useState("");
  const [title, setTitle] = useState("");
  const [files, setFiles] = useState([]);
  const [task, setTask] = useState("");
  const { task_id } = useParams();

  useEffect(() => {
    localStorage.setItem(`task_ID`, task_id);
  }, [task_id]);

  useEffect(() => {
    const savedID = localStorage.getItem(`task_ID`);
    if (savedID) {
      const id = parseInt(savedID.split(":")[1]);
      const filtered = tasks.find((task) => task.id === id);
      setTask(filtered);
      localStorage.setItem(`user_ID`, filtered.user.id);
    }
  }, [task_id]);

  useEffect(() => {
    const savedContent = localStorage.getItem("task_quill_content");
    if (savedContent) {
      setContent(savedContent);
    }
  }, []);

  const fileTypes = ["JPG", "PDF", "PNG", "GIF", "DOC"];

  const handleFileChange = (files) => {
    setFiles(files);
  };

  const handleSubmitResource = (e, task_id) => {
    e.preventDefault();
    const savedUserID = localStorage.getItem(`user_ID`);
    if (!content) {
      alert("content is empty");
      return;
    }

    const formData = new FormData();

    formData.append("content", content);
    formData.append("user_id", savedUserID);
    formData.append("tutors_username", task.user.username);
    formData.append("title", title);
    formData.append("order_id", task_id);
    formData.append("tutor_id", currentUser.id);

    if (files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        formData.append("files[]", files[i]);
      }
    }

    const token = localStorage.getItem("jwt");
    fetch(`${apiUrl}/order_packages`, {
      method: "POST",
      headers: {
        Accepts: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    })
      .then((resp) => {
        if (!resp.ok) {
          return resp.json().then((errorData) => {
            alert(errorData.message);
            throw new Error(errorData.message || "Something went wrong");
          });
        }
        return resp.json();
      })
      .then((data) => {
        setResources((prevResources) => [...prevResources, data]);
      })
      .catch((error) => {
        console.error("Error:", error.message);
      });
  };

  return (
    <div className=" p-4 m-4 rounded space-y-8 ">
      <div className="flex flex-col justify-start bg-white items-start space-y-4 shadow-md rounded-md px-8 py-4 border">
        <div className="flex flex-col gap-2 mt-2 w-full lg:w-1/2">
          <p className="m-0">
            <span className="text-gray-500 font-light pr-3">Type:</span>
            {task?.order_type}
          </p>
          <p className="m-0">
            <span className="text-gray-500 font-light pr-3">Discipline:</span>
            {task?.subject}
          </p>

          <p className="m-0">
            <span className="text-gray-500 font-light pr-3">
              Reference Style:
            </span>
            {task?.referencing_style}
          </p>
        </div>
        <div className="flex flex-col gap-2 mt-2 w-full lg:w-1/3">
          <p className="m-0">
            <span className="text-gray-500 font-light pr-3">Pages:</span>
            {task?.page_count}
          </p>
        </div>
        <div className="flex flex-col gap-2 mt-2 w-full lg:w-1/3">
          <p className="m-0">
            <span className="text-gray-500 font-light pr-3">Source count</span>
            {task?.source_count}
          </p>
        </div>
        <hr className="w-full border-gray-300" />
        <p className="font-medium">Details</p>
        <div
          className="w-full"
          dangerouslySetInnerHTML={{ __html: task?.order_details }}
          style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}
        ></div>

        {task?.files?.length > 0 && (
          <div>
            <hr className="w-full border-gray-300" />
            <p className="m-0">
              <span className="text-gray-500 font-light pr-3">
                Attachments:
              </span>
            </p>
            <div className="flex space-x-3">
              {task.files &&
                task.files.map((file, index) => (
                  <div
                    className="bg-white rounded shadow p-2 overflow-hidden"
                    key={index}
                  >
                    {file.content_type.startsWith("image/") && (
                      <img
                        src={file.url}
                        alt={file.name}
                        className="w-full h-auto mt-2 rounded"
                        style={{
                          maxHeight: "150px",
                          objectFit: "cover",
                        }}
                      />
                    )}
                    <a
                      href={file.url}
                      className="text-blue-500 hover:underline"
                      download
                    >
                      Download
                    </a>
                  </div>
                ))}
            </div>
          </div>
        )}
      </div>
      {task?.order_status !== "complete" && (
        <div className="flex flex-col shadow-md bg-white rounded-md px-8 py-4 space-y-8 border">
          <h1 className="font-bold">Solution</h1>
          <div className="flex flex-col space-y-4 w-full ">
            <label
              htmlFor="title"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Title
            </label>
            <div className="mt-2">
              <input
                type="text"
                required
                className="w-full rounded-md border py-1.5 text-gray-900 shadow ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6 focus:outline-none ps-2"
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
          </div>
          <div className="w-full">
            <ReactQuill
              theme="snow"
              value={content}
              onChange={(value) => {
                setContent(value);
                localStorage.setItem("task_quill_content", value);
              }}
              className="custom-quill-editor"
            />
          </div>
          <div className="w-full">
            <FileUploader
              multiple
              handleChange={handleFileChange}
              name="file"
              types={fileTypes}
            />
          </div>
          <div className=" flex justify-end">
            <button
              className="bg-teal-500 shadow-xl px-4 py-2 rounded"
              onClick={(e) => handleSubmitResource(e, task?.id)}
            >
              Submit
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default SingleTask;
