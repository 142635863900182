import React from "react";

function Contacts({ allUsers,  handleSelectUser }) {
  const filteredContacts = allUsers.filter((user) => user.role !== "admin");
  return (
    <div>
      {filteredContacts &&
        filteredContacts.map((contact) => {
          return (
            <div
              className="flex space-x-2 border-b py-1 w-full "
              onClick={() => {
                handleSelectUser(contact.id);
              }}
            >
              <div className="w-1/4 ">
                <img
                  src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                  className="object-cover h-full w-full rounded-full"
                  alt=""
                />
              </div>
              <div className="w-full">
                <div className="text-lg font-semibold capitalize">
                  {contact?.username}
                </div>
                <span className="text-gray-500">
                  {contact.last_message_content}
                </span>
              </div>
            </div>
          );
        })}
    </div>
  );
}

export default Contacts;
