import React, { useEffect, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

function TutorProfileSettings({ handleAccountUpdate, currentUser }) {
  const [username, setUsername] = useState("");
  const [phone_number, setPhoneNumber] = useState("");
  const [image, setImage] = useState("");
  const [email, setEmail] = useState("");

  useEffect(() => {
    setUsername(currentUser.username);
    setPhoneNumber(currentUser.phone_number);
    setImage(currentUser.image);
    
    setEmail(currentUser.email);
  }, []);

  const fileTypes = ["JPG", "PNG"];

  

  const handleChange = (image) => {
    setImage(image);
  };

  //!updates account
  function handleUserUpdate(e) {
    e.preventDefault();

    const formData = new FormData();

    formData.append("username", username);
    formData.append("phone_number", phone_number);
    formData.append("email", email);
    if (image instanceof Image) {
      formData.append("image", image);
    }

    handleAccountUpdate(formData, currentUser.id);
  }

  return (
    <div className="container mx-auto px-4 py-6">
      <div className="border rounded-lg shadow-lg p-6 max-w-lg mx-auto">
        <p className="font-bold text-xl mb-4">Update Profile</p>
        <form>
          {/* Username Field */}
          <div className="mb-4 flex flex-col">
            <label className="mb-2 text-gray-700 font-medium">Username</label>
            <input
              type="text"
              className="border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
              name="inputName"
              placeholder="Name"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>

          {/* Phone Number Field */}
          <div className="mb-4 flex flex-col">
            <label className="mb-2 text-gray-700 font-medium">
              Phone Number
            </label>
            <PhoneInput
              country={"us"}
              value={phone_number}
              onChange={(e) => setPhoneNumber(e.target.value)}
              className=" border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          {/* Email Field */}
          <div className="mb-4 flex flex-col">
            <label className="mb-2 text-gray-700 font-medium">Email</label>
            <input
              type="email"
              className="border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
              name="email"
              placeholder="example@email.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          {/* Profile Image Upload */}
          <div className="mb-4 flex flex-col">
            <label className="mb-2 text-gray-700 font-medium">
              Profile Image
            </label>
            <FileUploader
              handleChange={handleChange}
              name="image"
              types={fileTypes}
              className="border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          {/* Submit Button */}
          <div className="flex justify-end">
            <button
              type="submit"
              className="bg-blue-500 text-white font-semibold py-2 px-4 rounded-lg hover:bg-blue-600"
              onClick={(e) => handleUserUpdate(e)}
            >
              Update
            </button>
          </div>
        </form>
      </div>
    </div>

    // <div>
    //
    // </div>
  );
}

export default TutorProfileSettings;
