import React, { useContext, useEffect, useRef, useState } from "react";
import bigLogo from "../assets/images/bigLogo.png";
import logo from "../assets/images/logo.jpg";
import { NavLink, useNavigate } from "react-router-dom";
import profile from "../assets/images/profile.jpg";
import TimePassed from "./TimePassed";
import { UrlContext } from "../context/UrlProvider";

function Navbar({
  logged,
  setCurrentUser,
  currentUser,
  messages,
  setLogged,
  notificationsCount,
  setFilteredNotifications,
  setNotificationsCount,
  notifications,
  filteredNotifications,
  messagesCount,
  sendMessage,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [openNotification, setOpenNotification] = useState(false);
  const [openMessages, setOpenMessages] = useState(false);
  const navigate = useNavigate();
  const apiUrl = useContext(UrlContext);
  const scrollableDivRef = useRef()

  const [content, setContent] = useState("");
  const senderId = currentUser?.id;
  const receiverId = 1;
  sessionStorage.setItem("receiverId", receiverId);

  const toggleMenu = () => {
    setIsOpen((val) => !val);
  };

  const toggleNotification = () => {
    if (notificationsCount) {
      setOpenNotification((prevVal) => !prevVal);
    }
  };

  const toggleMessage = () => {
    if (messagesCount && currentUser.role === "student") {
      setOpenMessages((prevVal) => !prevVal);
    } else if (currentUser.role === "admin") {
      navigate("/dashboard/chatroom");
    } else if (messagesCount && currentUser.role === "tutor") {
      navigate("/dashboard/chatroom");
    } else {
      return;
    }
  };

   useEffect(() => {
     if (scrollableDivRef.current) {
       scrollableDivRef.current.scrollTop =
         scrollableDivRef.current.scrollHeight;
     }
   }, [messages]);

  const handleSendMessage = (e) => {
    e.preventDefault();

    if (content && receiverId && senderId) {
      // Ensure values are not null or empty
      sendMessage({ content, receiverId, senderId });
      setContent("");
    } else {
      console.error("Please fill in all fields before sending.");
    }
  };

  const handleLogout = () => {
    navigate("/");
    localStorage.removeItem("jwt");
    localStorage.removeItem("kqt_user_id");
    setCurrentUser(null);
    setLogged(false);
  };

  const displaySvg = (value) => {
    const type = value.notification_type;
    switch (type) {
      case "user created":
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.66674 10.1147L12.7947 3.98599L13.7381 4.92866L6.66674 12L2.42407 7.75733L3.36674 6.81466L6.66674 10.1147Z"
            fill="#047857"
          />
        </svg>;
        break;
      case "user updated":
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M18 6L6 18"
            stroke="#4B5563"
            stroke-width="1.25"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M6 6L18 18"
            stroke="#4B5563"
            stroke-width="1.25"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>;
        break;
      case "order created":
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.66674 10.1147L12.7947 3.98599L13.7381 4.92866L6.66674 12L2.42407 7.75733L3.36674 6.81466L6.66674 10.1147Z"
            fill="#047857"
          />
        </svg>;
        break;
      case "order assigned":
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M18 6L6 18"
            stroke="#4B5563"
            stroke-width="1.25"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M6 6L18 18"
            stroke="#4B5563"
            stroke-width="1.25"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>;
        break;
      case "resource created":
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.66674 10.1147L12.7947 3.98599L13.7381 4.92866L6.66674 12L2.42407 7.75733L3.36674 6.81466L6.66674 10.1147Z"
            fill="#047857"
          />
        </svg>;
        break;
      case "resource updated":
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M18 6L6 18"
            stroke="#4B5563"
            stroke-width="1.25"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M6 6L18 18"
            stroke="#4B5563"
            stroke-width="1.25"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>;
        break;
      case "resource deleted":
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.66674 10.1147L12.7947 3.98599L13.7381 4.92866L6.66674 12L2.42407 7.75733L3.36674 6.81466L6.66674 10.1147Z"
            fill="#047857"
          />
        </svg>;
        break;
      default:
        break;
    }
  };

  const handleClearNotifications = async () => {
    const token = localStorage.getItem("jwt");
    const user_id = localStorage.getItem("kqt_user_id");

    try {
      const resp = await fetch(
        `${apiUrl}/notifications/${user_id}/clear_recent`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (resp.ok) {
        setNotificationsCount(0);
        setFilteredNotifications([]);
        setOpenNotification(false);
      } else {
        console.error("Failed to clear notifications");
      }
    } catch (error) {
      console.error("Error clearing notifications:", error);
    }
  };

  return (
    <header className="fixed top-0 w-full bg-white shadow-md z-50 h-20 flex items-center">
      <div className="md:container md:mx-auto sm:px-24">
        <nav className="flex items-center justify-between" aria-label="Global">
          <div className="flex ">
            <NavLink to="/">
              <div className="flex gap-3">
                <span className="sr-only">Knowledge Quest Tutor</span>
                <img
                  className="h-16 w-auto"
                  src={bigLogo}
                  alt="knowledge quest tutor website logo"
                />
              </div>
            </NavLink>
          </div>
          <div className="flex lg:hidden md:ms-0 ms-32">
            <button
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
              onClick={toggleMenu}
            >
              <span className="sr-only">Open main menu</span>
              <svg
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                />
              </svg>
            </button>
          </div>
          <div className="hidden  lg:flex lg:gap-x-12 align-center justify-center items-center">
            <NavLink
              to="/materials"
              className="text-sm font-semibold leading-6 text-gray-900"
            >
              Study Material
            </NavLink>
            <NavLink
              to="/about"
              className="text-sm font-semibold leading-6 text-gray-900"
            >
              About
            </NavLink>
            <NavLink
              to="/contact_us"
              className="text-sm font-semibold leading-6 text-gray-900"
            >
              Contact
            </NavLink>
          </div>

          <div className="hidden lg:flex   align-center justify-center items-center">
            {currentUser?.role !== "tutor" && currentUser?.role !== "admin" && (
              <button
                className="rounded-md bg-indigo-600 px-2 py-1 me-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                onClick={() => navigate("/order")}
              >
                Make Order
              </button>
            )}
            {logged ? (
              <>
                <div className="text-sm font-semibold leading-6  text-gray-900 flex gap-2">
                  <button
                    className="relative text-gray-400 border-0 cursor-pointer"
                    onClick={() => toggleNotification()}
                  >
                    {notificationsCount > 0 && (
                      <div className="absolute  left-0 top-0  bg-red-500 rounded-full w-5 h-5 flex justify-center items-center">
                        <span className="text-xs text-white ">
                          {notificationsCount}
                        </span>
                      </div>
                    )}
                    <div className="p-1.5 ">
                      <span className="sr-only">View notifications</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        className="text-gray-600 w-5 h-5"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zM8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z" />
                      </svg>
                    </div>
                  </button>
                  <button
                    className=" relative text-gray-900 border-0 outline-0"
                    onClick={() => toggleMessage()}
                  >
                    {messagesCount > 0 && (
                      <div className="absolute  left-0 top-0  bg-red-500 rounded-full w-5 h-5 flex justify-center items-center">
                        <span className="text-xs text-white ">
                          {messagesCount}
                        </span>
                      </div>
                    )}
                    <div className="p-1.5 ">
                      <span className="sr-only">View Messages</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        className="size-6"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M8.625 9.75a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H8.25m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H12m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0h-.375m-13.5 3.01c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.184-4.183a1.14 1.14 0 0 1 .778-.332 48.294 48.294 0 0 0 5.83-.498c1.585-.233 2.708-1.626 2.708-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z"
                        />
                      </svg>
                    </div>
                  </button>
                </div>
                <div>
                  <NavLink to="/dashboard">
                    <p className=" inline-flex w-full justify-center items-center py-2 text-sm font-semibold text-gray-800 -inset ">
                      <img
                        alt=""
                        src={
                          currentUser?.image?.length
                            ? currentUser.image
                            : profile
                        }
                        className="h-12 w-12 rounded-full shadow-md border"
                      />
                    </p>
                  </NavLink>
                </div>
              </>
            ) : (
              <NavLink
                to="/login"
                className="text-sm font-semibold leading-6 text-gray-900"
              >
                <div className="flex justify-center align-center items-center gap-1">
                  Log in
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    className="size-4"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3"
                    />
                  </svg>
                </div>
              </NavLink>
            )}

            <button onClick={() => handleLogout()}>
              <div className="p-1.5 ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="size-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15.75 9V5.25A2.25 2.25 0 0 0 13.5 3h-6a2.25 2.25 0 0 0-2.25 2.25v13.5A2.25 2.25 0 0 0 7.5 21h6a2.25 2.25 0 0 0 2.25-2.25V15m3 0 3-3m0 0-3-3m3 3H9"
                  />
                </svg>
              </div>
            </button>
          </div>
        </nav>

        {/* Mobile menu, show/hide based on menu open state */}
        {isOpen && (
          <div className="lg:hidden" role="dialog" aria-modal="true">
            {/* Background backdrop, show/hide based on slide-over state */}
            <div className="fixed inset-0 z-50" onClick={toggleMenu}></div>

            <div className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
              <div className="flex items-center justify-between">
                <NavLink to="#" className="-m-1.5 p-1.5">
                  <span className="sr-only">Knowledge Quest Tutor</span>
                  <img className="h-8 w-auto" src={logo} alt="" />
                </NavLink>
                <button
                  className="-m-2.5 rounded-md p-2.5 text-gray-700"
                  onClick={toggleMenu}
                >
                  <span className="sr-only">Close menu</span>
                  <svg
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18 18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
              <div className="mt-6 flow-root">
                <div className="-my-6 divide-y divide-gray-500/10">
                  <div className="space-y-2 py-6">
                    {logged && (
                      <NavLink
                        to="/dashboard"
                        onClick={toggleMenu}
                        className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                      >
                        Dashboard
                      </NavLink>
                    )}

                    <NavLink
                      to="/materials"
                      onClick={toggleMenu}
                      className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      Study Resources
                    </NavLink>
                    <NavLink
                      to="/about"
                      onClick={toggleMenu}
                      className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      About
                    </NavLink>
                    <NavLink
                      to="/contact_us"
                      onClick={toggleMenu}
                      className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      Contact
                    </NavLink>

                    <button
                      className="rounded-md bg-indigo-600 px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      onClick={() => {
                        navigate("/order");
                        toggleMenu();
                      }}
                    >
                      Make Order
                    </button>
                  </div>

                  {!logged ? (
                    <div className="py-6">
                      <NavLink
                        to="/login"
                        onClick={toggleMenu}
                        className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                      >
                        Log in
                      </NavLink>
                    </div>
                  ) : (
                    <>
                      <button
                        onClick={() => handleLogout()}
                        className="text-sm font-semibold leading-6 text-gray-900 "
                      >
                        <div className="flex justify-center align-center gap-1">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            className="size-6"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M8.25 9V5.25A2.25 2.25 0 0 1 10.5 3h6a2.25 2.25 0 0 1 2.25 2.25v13.5A2.25 2.25 0 0 1 16.5 21h-6a2.25 2.25 0 0 1-2.25-2.25V15m-3 0-3-3m0 0 3-3m-3 3H15"
                            />
                          </svg>
                          Log out
                        </div>
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        {openNotification && (
          <div className=" rounded-md fixed right-0 p-4 h-auto  max-h-96">
            <div
              className="fixed inset-0 z-50"
              onClick={toggleNotification}
            ></div>
            <div className="overflow-y-auto fixed inset-y-0 p-4 border top-20 z-50 right-1 bg-white rounded-md h-auto  max-h-96">
              <div className="flex items-center justify-between">
                <p
                  tabindex="0"
                  className="focus:outline-none text-2xl font-semibold leading-6 text-gray-800"
                >
                  Notifications
                </p>
                <button
                  className="cursor-pointer "
                  onClick={() => toggleNotification()}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18 6L6 18"
                      stroke="#4B5563"
                      stroke-width="1.25"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M6 6L18 18"
                      stroke="#4B5563"
                      stroke-width="1.25"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
              </div>
              <div className="flex items-center justify-end mt-2">
                <button
                  className="text-red-500 text-sm underline"
                  onClick={handleClearNotifications}
                >
                  clear all
                </button>
              </div>

              {filteredNotifications &&
                filteredNotifications.map((value) => (
                  <div className="w-full p-3 bg-white border-b flex flex-shrink-0">
                    <div
                      tabindex="0"
                      aria-label="group icon"
                      role="img"
                      className="focus:outline-none w-8 h-8 border rounded-full border-gray-200 flex flex-shrink-0 items-center justify-center"
                    >
                      {() => displaySvg(value)}
                    </div>
                    <div className="pl-3 w-full flex items-center justify-between gap-4">
                      <div>
                        <div className="flex gap-2">
                          <p className=" focus:outline-none text-sm">
                            {value.notification_type}
                          </p>
                          <p className="text-sm text-indigo-700">
                            #{value.resource_id}
                          </p>
                        </div>
                        <div className="flex gap-2 ">
                          <p className="focus:outline-none text-xs leading-3 pt-1 text-gray-500 capitalize">
                            by {value.username}
                          </p>
                          <TimePassed value={value} />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}

              <div className="flex items-center justify-between">
                <hr className="w-full" />
                <p
                  tabindex="0"
                  className="focus:outline-none text-sm flex flex-shrink-0 leading-normal px-3 py-16 text-gray-500"
                >
                  Thats it for now :)
                </p>
                <hr className="w-full" />
              </div>
            </div>
          </div>
        )}
        {openMessages && (
          <div className="fixed right-0 bottom-0 p-4 h-auto  max-h-96">
            <div className="fixed inset-0 z-50" onClick={toggleMessage}></div>

            <div
              className="fixed right-0 bottom-8 z-50 chat-window w-full max-w-xs md:max-w-sm"
              id="chat_window_1"
              style={{ marginLeft: "10px" }}
            >
              <div className="w-full">
                <div className="bg-white shadow-md rounded-lg">
                  <div className="bg-gray-800 text-white px-4 py-2 flex items-center justify-between">
                    <h3 className="text-sm font-semibold flex items-center">
                      <span className="material-icons mr-1">chat</span> Support
                    </h3>
                    <div className="flex items-center space-x-2">
                      <button className="text-gray-400 hover:text-white focus:outline-none">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="size-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M5 12h14"
                          />
                        </svg>
                      </button>
                      <button
                        className="text-gray-400 hover:text-white focus:outline-none"
                        onClick={toggleMessage}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="size-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M6 18 18 6M6 6l12 12"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                  <div
                    className="overflow-y-auto h-64 p-4 space-y-4 bg-gray-100"
                    ref={scrollableDivRef}
                  >
                    <div>
                      {messages &&
                        messages.map((message) => (
                          <>
                            {currentUser.id === message.sender_id ? (
                              <div className="flex items-end justify-end space-x-2 mb-2">
                                <div className="bg-blue-500 text-white p-2 rounded-lg text-sm">
                                  <p>{message.content}</p>
                                  <time className="text-xs text-gray-200">
                                    Timothy • 51 min
                                  </time>
                                </div>
                                <img
                                  src="http://www.bitrebels.com/wp-content/uploads/2011/02/Original-Facebook-Geek-Profile-Avatar-1.jpg"
                                  alt="User avatar"
                                  className="w-8 h-8 rounded-full"
                                />
                              </div>
                            ) : (
                              <div className="flex items-end space-x-2 mb-2">
                                <img
                                  src="http://www.bitrebels.com/wp-content/uploads/2011/02/Original-Facebook-Geek-Profile-Avatar-1.jpg"
                                  alt="User avatar"
                                  className="w-8 h-8 rounded-full"
                                />
                                <div className="bg-gray-300 text-gray-900 p-2 rounded-lg text-sm">
                                  <p>{message.content}</p>
                                  <time className="text-xs text-gray-600">
                                    Timothy • 51 min
                                  </time>
                                </div>
                              </div>
                            )}
                          </>
                        ))}
                    </div>
                  </div>
                  <div className="p-2 border-t bg-gray-50">
                    <div className="flex items-center">
                      <input
                        id="btn-input"
                        type="text"
                        className="flex-1 border border-gray-300 rounded-l-lg p-2 text-sm"
                        placeholder="Write your message here..."
                        onChange={(e) => setContent(e.target.value)}
                      />
                      <button
                        className="bg-blue-500 text-white rounded-r-lg px-4 py-2 text-sm hover:bg-blue-600"
                        onClick={(e) => handleSendMessage(e)}
                      >
                        Send
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </header>
  );
}

export default Navbar;
