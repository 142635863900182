import React, { useContext, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useNavigate } from "react-router-dom";
import welcomeBack from "../assets/images/welcomeBack.png";
import { UrlContext } from "../context/UrlProvider";
import { X } from "react-feather";

function Login({ handleSubmit }) {
  const navigate = useNavigate();
  const apiUrl = useContext(UrlContext)

  const [email, setEmail] = useState("")

  const [inputValue, setInputValue] = useState("");
  const [password, setPassword] = useState("");

  const [openReset, setOpenReset] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isEmail, setIsEmail] = useState(true);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const loginData = {
      isEmail,
      password,
      inputValue,
    };
    handleSubmit(loginData);
  };

  const handleOpenReset = () => {
    setOpenReset((prevVal) => !prevVal);
  };

   const closeOverlay = () => setOpenReset(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    setIsEmail(isChecked);
  };

  const handleResetPassword = async (e) => {
        e.preventDefault()
        try {
          const response = await fetch( apiUrl + '/password_reset',{
            method: "POST",
            headers: {
              'Content-Type': 'application/json',
              Accepts: 'application/json'
            },
            body:JSON.stringify({
                email,
            })
          })

          const data = await response.json();
          if(response.ok){
            console.log('data',data); 
          }else {
              console.log('Failed',data)
              
          }
          // Handle success, maybe show a success message
        } catch (error) {
          console.error('Error resetting password:', error);
          // Handle error, show an error message to the user
        }
      };

  return (
    <main>
      <div className="flex justify-center items-center pt-28 h-screen">
        {openReset && (
          <div className="overlay">
            <div
              className="w-full h-full z-30 flex justify-center items-center"
              onClick={closeOverlay}
            >
              <div
                className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm bg-white rounded-xl p-6 z-50"
                onClick={(e) => e.stopPropagation()}
              >
                <div className="flex justify-end">
                  <button onClick={handleOpenReset}>
                    <X />
                  </button>
                </div>
                <h2 className="text-2xl font-bold text-center text-gray-800">
                  Reset Password
                </h2>
                <p className="text-sm text-gray-600 text-center mb-4">
                  Enter your email to reset your password
                </p>
                <form
                  onSubmit={(e) => handleResetPassword(e)}
                  className="space-y-4"
                >
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm/6 font-medium text-gray-900"
                    >
                      Email address
                    </label>
                    <div className="mt-2">
                      <input
                        id="email"
                        name="email"
                        type="email"
                        required
                        autoComplete="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                      />
                    </div>
                  </div>

                  <div className="mt-4">
                    <button
                      type="submit"
                      className="w-full bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded-lg shadow focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    >
                      Send Reset Link
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
        <div className="bg-white rounded-lg lg:w-6/12 sm:w-8/12 w-full border shadow-lg">
          <div className="flex justify-center items-start ">
            <div
              className="hidden lg:flex lg:w-4/12 w-full p-0"
              style={{ height: "80vh" }}
            >
              <div className="w-full h-full">
                <img
                  className="w-full h-full object-cover rounded-l-lg"
                  src={welcomeBack}
                  alt="info"
                />
              </div>
            </div>

            <div className="lg:w-8/12 w-full p-6">
              <div className="flex flex-col gap-20">
                <div className="flex justify-between items-start px-2">
                  <div className="w-10/12">
                    <p className="text-lg font-bold m-0">Login Form</p>
                    <p className="text-2xl font-bold m-0">Welcome back</p>
                  </div>
                  <div className="w-1/12 text-right">
                    <button type="button" onClick={() => navigate("/")}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        className="size-6"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M6 18 18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>
                </div>

                <form onSubmit={(e) => handleFormSubmit(e)}>
                  <div className="mb-3">
                    <input
                      type="checkbox"
                      id="emailPhoneToggle"
                      className="hidden"
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                    />
                    <label
                      htmlFor="emailPhoneToggle"
                      className="grid grid-cols-2 rounded-lg border"
                    >
                      <span
                        className={`flex justify-center items-center p-2 rounded text-sm font-medium ${
                          !isChecked
                            ? "text-primary bg-gray-300"
                            : "text-gray-700"
                        }`}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="1em"
                          height="1em"
                          viewBox="0 0 36 36"
                          className="mr-2"
                        >
                          <path
                            fill="currentColor"
                            d="M32 6H4a2 2 0 0 0-2 2v20a2 2 0 0 0 2 2h28a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2m-1.54 22H5.66l7-7.24l-1.44-1.39L4 26.84V9.52l12.43 12.37a2 2 0 0 0 2.82 0L32 9.21v17.5l-7.36-7.36l-1.41 1.41ZM5.31 8h25.07L17.84 20.47Z"
                          />
                          <path fill="none" d="M0 0h36v36H0z" />
                        </svg>
                        Email
                      </span>

                      <span
                        className={`flex justify-center items-center p-2 rounded text-sm  font-medium ${
                          isChecked
                            ? "text-primary bg-gray-300"
                            : "text-gray-700"
                        }`}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="1em"
                          height="1em"
                          viewBox="0 0 256 256"
                          className="mr-2 "
                        >
                          <path
                            fill="currentColor"
                            d="m221.59 160.3l-47.24-21.17a14 14 0 0 0-13.28 1.22a5 5 0 0 0-.56.42l-24.69 21a1.88 1.88 0 0 1-1.68.06c-15.87-7.66-32.31-24-40-39.65a1.91 1.91 0 0 1 0-1.68l21.07-25a6 6 0 0 0 .42-.58a14 14 0 0 0 1.12-13.27L95.73 34.49a14 14 0 0 0-14.56-8.38A54.24 54.24 0 0 0 34 80c0 78.3 63.7 142 142 142a54.25 54.25 0 0 0 53.89-47.17a14 14 0 0 0-8.3-14.53M176 210c-71.68 0-130-58.32-130-130a42.23 42.23 0 0 1 36.67-42h.23a2 2 0 0 1 1.84 1.31l21.1 47.11a2 2 0 0 1 0 1.67l-21.11 25.06a5 5 0 0 0-.43.57a14 14 0 0 0-.91 13.73c8.87 18.16 27.17 36.32 45.53 45.19a14 14 0 0 0 13.77-1c.19-.13.38-.27.56-.42l24.68-21a1.92 1.92 0 0 1 1.6-.1l47.25 21.17a2 2 0 0 1 1.21 2A42.24 42.24 0 0 1 176 210"
                          />
                        </svg>
                        Phone Number
                      </span>
                    </label>
                  </div>

                  <div className="mb-2 text-left">
                    <label htmlFor="inputValue" className="form-label m-0">
                      {!isChecked ? "Email" : "Phone number"}
                    </label>
                    {!isChecked ? (
                      <input
                        type="email"
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        value={inputValue}
                        id="email"
                        name="email"
                        onChange={(e) => setInputValue(e.target.value)}
                        required
                      />
                    ) : (
                      <PhoneInput
                        country={"us"}
                        value={inputValue}
                        onChange={(phone) => setInputValue(phone)}
                      />
                    )}
                  </div>

                  <div className="mb-2 text-left">
                    <label
                      htmlFor="exampleInputPassword1"
                      className="form-label m-0"
                    >
                      Password <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="password"
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      id="exampleInputPassword1"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                  </div>

                  <button
                    type="submit"
                    className="shadow-lg bg-blue-700 py-3 text-white font-bold my-4 w-full rounded"
                  >
                    Submit
                  </button>
                  <div className="pt-3 text-right">
                    <p
                      className=" cursor-pointer"
                      onClick={() => handleOpenReset()}
                    >
                      <small>
                        <em>forgot password?</em>
                      </small>
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Login;
