import React from "react";
import { topicsArr } from "../assets/ArrayData/topicsArr";
import students from "../assets/images/student.jpg";
import { useNavigate } from "react-router-dom";

function StudyMaterials({resources}) {

  const navigate = useNavigate()

  const handleGetResource = (value) => {

    const resource_id = value.id;
    navigate(`/materials/order_summary/:${resource_id}`);
  }
  return (
    <div>
      <div className="section courses-section bg-[#f8ecef] py-10">
        <div className="container mx-auto py-5">
          <div className="grid">
            <div className="col-12 text-center">
              <div className="mx-auto max-w-xl text-center">
                <h4 className="text-lg font-semibold text-center text-gray-700 mb-2">
                  <span>Study Resources</span>
                </h4>
                <h2 className="text-3xl font-bold">Most Popular Disciplines</h2>
              </div>
            </div>  
          </div>
          <div className="flex flex-wrap items-center justify-center space-y-4">
            {/* Dynamic cards for topicsArr */}
            {resources &&
              resources.map((resource, index) => (
                <div key={index} className="col-lg-6 col-md-6 col-11 p-2">
                  <article className="flex flex-col dark:bg-gray-50">
                    <div>
                      <img
                        alt=""
                        className="object-cover w-52 cover h-52 max-h-52 max-w-52 dark:bg-gray-500"
                        src={
                          resource.cover_image?.length
                            ? resource.cover_image
                            : "https://picsum.photos/seed/59/300/200"
                        }
                      />
                    </div>
                    <div className="flex flex-col flex-1 p-2">
                      <a
                        rel="noopener noreferrer"
                        href="#"
                        className="text-xs tracking-wider uppercase hover:underline dark:text-violet-600"
                      >
                        {resource.content_type}
                      </a>
                      <h3 className="flex-1 pt-2 text-lg font-semibold leading-snug">
                        {resource.subject}
                      </h3>
                      <div className="flex flex-wrap justify-between pb-3 space-x-2 text-xs dark:text-gray-600">
                        <span>
                          {resource.page_count}
                          {resource.page_count > 1 ? "pages" : "page"}
                        </span>
                        <span>
                          {resource.review?.length && (
                            <p className="text-sm text-gray-500">
                              {resource.review.length}
                              {resource.review > 1 ? "reviews" : "review"}
                            </p>
                          )}
                        </span>
                      </div>
                      <div className="flex items-center justify-between">
                        <button
                          className="btn text-black hover:bg-blue-900 px-3 py-1 rounded-md hover:text-white font-bold flex items-center justify-between border"
                          onClick={() => handleGetResource(resource)}
                        >
                          Get
                        </button>
                        <div className="text-lg font-semibold text-green-500">
                          Free
                        </div>
                      </div>
                    </div>
                  </article>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default StudyMaterials;
