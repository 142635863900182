import React from "react";
import { useNavigate } from "react-router-dom";
import AdminIndex from "./adminDashboardComponents/AdminIndex";
import StudentIndex from "./studentDashboardComponents/StudentIndex";
import TutorsProfile from "./tutorDashboardComponents/TutorsProfile";

function Index({
  tasks,
  allUsers,
  allReviews,
  chats,
  currentUser,
  toggleSidebar,
  orders,
  greeting,
  orderPackages,
}) {
  const navigate = useNavigate();

  function handleOpenPreview(task) {
    const task_id = task.id;
    navigate(`/admin/tasks/:${task_id}`);
  }

  return (
    <>
      {currentUser.role === "admin" && (
        <main class="p-3 sm:p-6 space-y-6">
          <AdminIndex
            tasks={tasks}
            orders={orders}
            orderPackages={orderPackages}
            allUsers={allUsers}
            allReviews={allReviews}
            chats={chats}
            currentUser={currentUser}
            toggleSidebar={toggleSidebar}
          />
        </main>
      )}
      {currentUser.role === "tutor" && (
        <main class="p-3 sm:p-6 space-y-6">
          <TutorsProfile
            tasks={tasks}
            allUsers={allUsers}
            allReviews={allReviews}
            chats={chats}
            currentUser={currentUser}
            toggleSidebar={toggleSidebar}
          />
        </main>
      )}
      {currentUser.role === "student" && (
        <>
          <main>
            <StudentIndex
              currentUser={currentUser}
              toggleSidebar={toggleSidebar}
              orders={orders}
              greeting={greeting}
            />
          </main>
        </>
      )}
    </>
  );
}

export default Index;
