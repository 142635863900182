import { format } from "date-fns";
import React from "react";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";

function TutorTasks({ tasks, currentUser }) {

  const navigate = useNavigate()

   const ExpandedComponent = ({ data }) => (
     <>
       <div className="p-4 bg-gray-100 rounded-md">
         <h3 className="font-semibold text-lg">Order Details</h3>
         <p>
           <strong>Order Type:</strong> {data.order_type}
         </p>
         <p>
           <strong>Page Count:</strong> {data.page_count}
         </p>
         <p>
           <strong>Subject:</strong> {data.subject}
         </p>
         <p>
           <strong>Deadline:</strong> {new Date(data.deadline).toLocaleString()}
         </p>
         <p>
           <strong>Order Status:</strong> {data.order_status}
         </p>
         <p>
           <strong>Tutor's ID:</strong> {data.tutors_id}
         </p>
         <p>
           <strong>Referencing Style:</strong> {data.referencing_style}
         </p>
         <p>
           <strong>Source Count:</strong> {data.source_count}
         </p>

         <div className="mt-4">
           <h4 className="font-semibold">Order Details</h4>
           <div
             dangerouslySetInnerHTML={{ __html: data.order_details }}
             className="text-gray-700"
           ></div>
         </div>

         {data.files && data.files.length > 0 && (
           <div className="mt-4">
             <h4 className="font-semibold">Files</h4>
             {data.files.map((file, index) => (
               <div key={index} className="mt-2">
                 <p>
                   <strong>File Name:</strong> {file.name}
                 </p>
                 <p>
                   <strong>File Type:</strong> {file.content_type}
                 </p>
                 <a href={file.url} className="text-blue-600" download>
                   Download
                 </a>
               </div>
             ))}
           </div>
         )}
       </div>
     </>
   );

     const tasksColumns = [
       {
         name: "ID",
         selector: (row) => row.id,
         sortable: true,
         reorder: true,
       },
       {
         name: "Type",
         selector: (row) => row.order_type,
         sortable: true,
         reorder: true,
       },
       {
         name: "Amount",
         selector: (row) => row.amount,
         sortable: true,
         reorder: true,
       },
       {
         name: "Payment",
         selector: (row) => (row.payment_status ? "Paid" : "Pending"),
         sortable: true,
         reorder: true,
       },
       {
         name: "Status",
         selector: (row) => row.order_status,
         sortable: true,
         reorder: true,
       },
       {
         name: "Pages",
         selector: (row) => row.page_count,
         sortable: true,
         reorder: true,
       },
       {
         name: "Assign",
         selector: (row) => (
           <>
             <button onClick={() => handleOpenOrder(row)}>
               <span className="inline-flex items-center text-xs font-medium">
                 <svg
                   xmlns="http://www.w3.org/2000/svg"
                   fill="none"
                   viewBox="0 0 24 24"
                   stroke-width="1.5"
                   stroke="currentColor"
                   class="size-6"
                 >
                   <path
                     stroke-linecap="round"
                     stroke-linejoin="round"
                     d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                   />
                 </svg>
               </span>
             </button>
           </>
         ),
       },
     ];

  const handleOpenOrder = (value) => {
    console.log("first");
    const order_id = value.id;
    navigate(`/dashboard/tasks/:${order_id}`);
  };

  return (
    <div className="container mx-auto">
      <div className="flex justify-center items-center space-y-4">
        <div className="w-full">
          <DataTable
            title="Tasks List"
            pagination
            columns={tasksColumns}
            data={tasks}
            expandableRows
            expandableRowsComponent={ExpandedComponent}
          />
        </div>
      </div>
    </div>
  );
}

export default TutorTasks;
