import React, { useContext } from "react";
import { UrlContext } from "../../context/UrlProvider";

function ReviewOrderPackage({ orderPackages }) {
  const apiUrl = useContext(UrlContext);
  const orderPackage = orderPackages[0];
  const order = orderPackage?.order;

  const handleStudentOrder = () => {
    const id = orderPackage.id;
    fetch(`${apiUrl}/order_packages/${id}/complete_order`)
      .then((resp) => {
        if (!resp.ok) {
          throw new Error("Failed to fetch orders.");
        } else {
          resp.json();
        }
      })
      .then((data) => {
        console.log("data", data);
      });
  };

  return (
    <div className="container mx-auto p-8">
      {orderPackages.length < 1 && (
        <div className="mx-auto max-w-sm  text-sm h-96 flex items-center justify-center">
          <div className="flex flex-col items-center space-y-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="size-12"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M3.75 9.776c.112-.017.227-.026.344-.026h15.812c.117 0 .232.009.344.026m-16.5 0a2.25 2.25 0 0 0-1.883 2.542l.857 6a2.25 2.25 0 0 0 2.227 1.932H19.05a2.25 2.25 0 0 0 2.227-1.932l.857-6a2.25 2.25 0 0 0-1.883-2.542m-16.5 0V6A2.25 2.25 0 0 1 6 3.75h3.879a1.5 1.5 0 0 1 1.06.44l2.122 2.12a1.5 1.5 0 0 0 1.06.44H18A2.25 2.25 0 0 1 20.25 9v.776"
              />
            </svg>

            <h2 className="text-xl font-bold mb-4">No Review Packages</h2>
          </div>
        </div>
      )}

      {orderPackages &&
        orderPackages.map((orderPackage) => {
          return (
            <div className="max-w-lg bg-indigo-600 shadow-lg rounded-lg">
              <div className="px-6 py-5">
                <div className="flex items-start">
                  <svg
                    className="fill-current flex-shrink-0 mr-5"
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                  >
                    <path
                      className="text-indigo-300"
                      d="m16 14.883 14-7L14.447.106a1 1 0 0 0-.895 0L0 6.883l16 8Z"
                    />
                    <path
                      className="text-indigo-200"
                      d="M16 14.619v15l13.447-6.724A.998.998 0 0 0 30 22V7.619l-14 7Z"
                    />
                    <path
                      className="text-indigo-500"
                      d="m16 14.619-16-8V21c0 .379.214.725.553.895L16 29.619v-15Z"
                    />
                  </svg>
                  <div className="flex-grow truncate">
                    <div className="w-full sm:flex justify-between items-center mb-3">
                      <h2 className="text-2xl leading-snug font-extrabold text-gray-50 truncate mb-1 sm:mb-0">
                        {orderPackage.title}
                      </h2>
                    </div>
                    <div className="flex items-end justify-between whitespace-normal">
                      <div className="max-w-md text-indigo-100">
                        <p className="mb-2">{orderPackage.content}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
}

export default ReviewOrderPackage;
