import React, { useState } from "react";
import { X } from "react-feather";

function ReceivedPackages({ orderPackages }) {
  console.log("Received", { orderPackages });

  const [openReader, setOpenReader] = useState(false);

  const handleOpenReaderOverlay = () => {
    setOpenReader((prevVal) => !prevVal);
  };

  return (
    <div className="container mx-auto p-4">
      <h2 className="font-bold text-2xl mb-6 text-center text-gray-800">
        Ordered Packages
      </h2>

      <div className="flex  gap-4">
        {orderPackages?.length > 0 &&
          orderPackages.map((orderPackage) => (
            <div
              key={orderPackage.id}
              className="sm:w-1/2 shadow-lg rounded-lg bg-white p-6  flex flex-col justify-between overflow-hidden"
            >
              <dl>
                <div className="px-2 py-1 sm:grid sm:grid-cols-2  sm:gap-4 sm:px-0">
                  <dt className="text-sm/6 font-medium text-gray-900">
                    Title:
                  </dt>
                  <dd className="mt-1  text-gray-700 sm:col-span-1 sm:mt-0 overflow-hidden">
                    {orderPackage.title}
                  </dd>
                </div>
                <div className="px-2 py-1 sm:grid sm:grid-cols-2  sm:gap-4 sm:px-0">
                  <dt className="text-sm/6 font-medium text-gray-900">Type:</dt>
                  <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-1 sm:mt-0">
                    {orderPackage.order?.order_type}
                  </dd>
                </div>
                <div className="px-2 py-1 sm:grid sm:grid-cols-2  sm:gap-4 sm:px-0">
                  <dt className="text-sm/6 font-medium text-gray-900">
                    Subject:
                  </dt>
                  <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-1 sm:mt-0">
                    {orderPackage.order.subject}
                  </dd>
                </div>
                <div className="px-2 py-1 sm:grid sm:grid-cols-2  sm:gap-4 sm:px-0">
                  <dt className="text-sm/6 font-medium text-gray-900">
                    Attachments:
                  </dt>
                  <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-1 sm:mt-0">
                    {orderPackage.files ? orderPackage.files.length : 0}
                  </dd>
                </div>
              </dl>

              <div className="flex justify-end mt-4">
                <button
                  className="bg-blue-500 text-white py-2 px-4 rounded w-full"
                  onClick={() => handleOpenReaderOverlay()}
                >
                  Open
                </button>
              </div>

              {openReader && (
                <div className="overlay">
                  <div className="w-full h-full p-6 my-6 overflow-y-scroll bg-white">
                    <div className="flex justify-end">
                      <button
                        className="rounded "
                        onClick={() => handleOpenReaderOverlay()}
                      >
                        <X />
                      </button>
                    </div>
                    <h2 className="font-bold text-2xl mb-6 text-center text-gray-800">
                      {orderPackage.title}
                    </h2>
                    <div className="mb-4 overflow-y-auto max-h-40">
                      <div
                        className="text-gray-700 mb-4 overflow-hidden"
                        dangerouslySetInnerHTML={{
                          __html: orderPackage.content,
                        }}
                      ></div>
                    </div>

                    {orderPackage.files?.length > 0 && (
                      <div className="mt-4">
                        <hr className="my-4 border-gray-300" />
                        <p className="text-gray-500 font-light mb-2">
                          Attachments:
                        </p>
                        <div className="flex flex-wrap gap-4">
                          {orderPackage.files.map((file, index) => (
                            <div
                              className="bg-white rounded shadow p-2 w-full sm:w-48 md:w-40 lg:w-32"
                              key={index}
                            >
                              {file.content_type.startsWith("image/") && (
                                <img
                                  src={file.url}
                                  alt={file.name}
                                  className="w-full h-auto mt-2 rounded"
                                  style={{
                                    maxHeight: "150px",
                                    objectFit: "cover",
                                  }}
                                />
                              )}
                              <a
                                href={file.url}
                                className="text-blue-500 hover:underline block mt-2 text-center"
                                download
                              >
                                Download
                              </a>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          ))}
      </div>
    </div>
  );
}

export default ReceivedPackages;
