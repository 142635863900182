import React from 'react'
import { NavLink, useNavigate } from 'react-router-dom';
import { motion } from "framer-motion";
import fb from "../assets/images/fb.svg"
import x from "../assets/images/x.svg";
import yt from "../assets/images/yt.svg";
import ig from "../assets/images/ig.svg"
import bigLogo from "../assets/images/grayBigLogo.svg"

function Footer() {

    const navigate = useNavigate()
  return (
    <div className="footer bg-gray-900 text-gray-300 px-4 ">
      <div>
        <div className="grid grid-cols-12 md:flex-row justify-around items-center gap-6 lg:gap-4 py-6 lg:py-20  w-full ">
          <div className=" text-center lg:text-left md:space-y-5 col-span-12 md:col-span-6">
            <motion.p
              className="text-3xl font-bold"
              initial={{ opacity: 0, y: -80 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.73 }}
              viewport={{ once: true }}
            >
              <NavLink className="footer_logo" to="/">
                <img
                  className="h-16 w-auto"
                  src={bigLogo}
                  alt="knowledge quest tutor website logo"
                />
              </NavLink>
            </motion.p>
            <motion.p
              className="mt-3"
              initial={{ opacity: 0, y: -80 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.53 }}
              viewport={{ once: true }}
            >
              The use of vibrant colors and intuitive layout aims to make the
              learning journey enjoyable, while responsive design guarantees a
              consistent experience across devices.
            </motion.p>
          </div>

          <div className=" text-center lg:text-start md:space-y-10  col-span-12 sm:col-span-4 lg:col-span-2">
            <p className="font-bold text-white">Useful Links</p>
            <ul className="space-y-3 lg:space-y-2">
              <li>
                <NavLink to="/about">About Us</NavLink>
              </li>
              <li>
                <NavLink to="#">Blog</NavLink>
              </li>
              <li>
                <NavLink to="/contact">Contact Us</NavLink>
              </li>
              <li>
                <NavLink to="/pricing">Pricing</NavLink>
              </li>
              <li>
                <NavLink to="/reviews">Testimonials</NavLink>
              </li>
            </ul>
          </div>

          <div className="col-span-12 sm:col-span-4 lg:col-span-2 text-center lg:text-start md:space-y-10">
            <p className="font-bold text-white">Courses</p>
            <ul className="space-y-3 lg:space-y-2">
              <li>Business & Finance</li>
              <li>Computer Science</li>
              <li>Economics</li>
              <li>History</li>
              <li>Writing</li>
            </ul>
          </div>

          <div className="col-span-12 sm:col-span-4 lg:col-span-2 text-center lg:text-start md:space-y-10">
            <p className="font-bold text-white">Support</p>
            <ul className="space-y-3 lg:space-y-2">
              <li onClick={() => navigate("/contact")}>Help Center</li>
              <li onClick={() => navigate("/contact")}>Contact Us</li>
              <li>Terms of Service</li>
              <li>Legal</li>
              <li>Privacy Policy</li>
            </ul>
          </div>
        </div>
        <hr className="w-full border-t border-gray-700" />
        <div className="w-full flex justify-center flex-col md:flex-row md:justify-between py-4 sm:py-3 ">
          <p>
            &copy; 2024 Knowledge quest tutor ,Inc. All rights reserved
          </p>
          <motion.ul
            className="flex gap-4 lg:gap-3 mt-4 sm:mt-3   items-center lg:justify-start"
            initial={{ opacity: 0, y: -80 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3 }}
            viewport={{ once: true }}
          >
            <li>
              <img
                src={fb}
                alt="facebook"
                className="sm:h-5 sm:w-5 lg:h-6 lg:w-6"
              />
            </li>
            <li>
              <img src={x} alt="x" className="sm:h-5 sm:w-5 lg:h-5 lg:w-6" />
            </li>
            <li>
              <img
                src={yt}
                alt="youtube"
                className="sm:h-5 sm:w-5 lg:h-6 lg:w-6"
              />
            </li>
            <li>
              <img
                src={ig}
                alt="instagram"
                className="sm:h-5 sm:w-5 lg:h-6 lg:w-6"
              />
            </li>
          </motion.ul>
        </div>
      </div>
    </div>
  );
}

export default Footer