import React from "react";

function RecentChats({
  recent,
  currentUser,
  handleFetchMessages,
  allUsers,
  handleSelectUser
}) {

    const value =
      recent.sender_id === currentUser.id
        ? recent.receiver_id
        : recent.sender_id;
    const otherUserId = value
  

  return (
    <>
      <div
        className="flex space-x-2 border-b py-1 w-full "
        onClick={() => {
          handleFetchMessages(recent.id);
          handleSelectUser(otherUserId);
        }}
      >
        <div className="w-1/4 ">
          <img
            src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
            className="object-cover h-full w-full rounded-full"
            alt=""
          />
        </div>
        <div className="w-full">
          <div className="text-lg font-semibold capitalize">
            {allUsers[otherUserId - 1]?.username}
          </div>
          {recent.last_message_content.length > 15
            ? `${recent.last_message_content.slice(0, 15)} ...`
            : recent.last_message_content}
        </div>
      </div>
    </>
  );
}

export default RecentChats;
