import React from "react";

function NotificationPage({ filteredNotifications, notifications }) {

  return (
    <div className="container mx-auto md:px-8">
      <div className="flex justify-center w-full mt-4">
        {filteredNotifications.map((value) => {
          return (
            <div className="w-1/2 ">
              <div className="flex flex-col bg-white shadow-md hover:shadow-xl rounded-md pe-2">
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-16 h-16 rounded-md p-3 border border-blue-100 text-blue-400 bg-blue-50"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      ></path>
                    </svg>
                    <div className="flex flex-col ml-3">
                      <div className="font-medium leading-none">
                        Delete Your Acccount ?
                      </div>
                      <p className="text-sm text-gray-600 leading-none mt-1">
                        By deleting your account you will lose your all data
                      </p>
                    </div>
                  </div>
                  <button className="flex-no-shrink bg-red-500 px-2 ml-4 py-1.5 text-sm shadow-sm hover:shadow-lg font-medium tracking-wider border-2 border-red-500 text-white rounded-md">
                    view
                  </button>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default NotificationPage;
