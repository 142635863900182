import React, { useEffect, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { referencing } from "../../assets/ArrayData/referencing";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Select from "react-tailwindcss-select";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

function FormSectionTwo({
  handleFileChange,
  handleDetailChange,
  handleRefChange,
  orderDetails,
  handleSourceCount,
  prevStep,
  values,
  handleSubmitOrder,
  currentUser,
  handleChange,
  handleLogin,
}) {
  const { referencing_style, source_count, email, phoneNumber } = values;
  const [referencingStyle, setReferencingStyle] = useState("");

  const fileTypes = ["JPG", "PDF", "PNG", "GIF", "DOC"];

  useEffect(() => {
    const savedContent = localStorage.getItem("task_quill_content");
    if (savedContent) {
      handleDetailChange("order_details", savedContent);
    }
  }, []);
  return (
    <div>
      <div className="bg-white rounded-md shadow-lg p-4 md:p-8 mb-6">
        <div className="space-y-4">
          <div className="text-left relative">
            <div className="absolute hidden xl:block w-1/2 ps-4 -left-3/4 ">
              <section className=" dark:bg-dark ">
                <div className="mx-auto px-4 sm:container">
                  <div>
                    <h2 className="mb-2 text-xl font-semibold ">
                      Order description
                    </h2>
                    <p className="mb-6 text-sm text-body-color dark:text-dark-6">
                      Providing a clear order description ensures our qualified
                      tutors can tailor their support to meet your specific
                      needs.
                    </p>
                  </div>
                </div>
              </section>
            </div>
            <p className="font-semibold">Describe Your Order</p>
            <p className="m-0">
              <small>
                <em>
                  Providing detailed and specific information enhances the
                  quality of assistance you'll receive.
                </em>
              </small>
            </p>
          </div>

          <div className="w-full">
            <ReactQuill
              theme="snow"
              value={orderDetails}
              onChange={(value) => handleDetailChange("order_details", value)}
              className="custom-quill-editor"
            />
          </div>

          <div className="w-full">
            <FileUploader
              multiple
              handleChange={handleFileChange}
              name="file"
              types={fileTypes}
            />
          </div>

          <div className="w-full">
            <div className="flex flex-wrap gap-4">
              <div className="w-full sm:w-1/2 ">
                <label className="block font-medium mb-1">
                  Referencing style
                </label>
                <Select
                  value={referencingStyle}
                  onChange={(e) => {
                    handleRefChange("referencing_style", e);
                    setReferencingStyle(e);
                  }}
                  options={referencing}
                  className="block w-full border rounded px-3 py-2"
                  placeholder="Select one"
                />
              </div>

              <div className="w-full sm:w-1/2 ">
                <label className="block font-medium mb-1">
                  Number of sources
                </label>

                <div className="h-10 w-28 bg-gray-50 flex border border-gray-200 rounded items-center mt-1">
                  <button
                    type="button"
                    className="cursor-pointer outline-none focus:outline-none border-r border-gray-200 transition-all text-gray-500 hover:text-blue-600"
                    onClick={() => handleSourceCount("-")}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      className="h-4 w-4 mx-2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M5 12h14"
                      />
                    </svg>
                  </button>
                  <input
                    placeholder="0"
                    className="px-2 text-center appearance-none outline-none text-gray-800 w-full bg-transparent"
                    value={source_count}
                  />
                  <button
                    type="button"
                    className="cursor-pointer outline-none focus:outline-none border-l border-gray-200 transition-all text-gray-500 hover:text-blue-600"
                    onClick={() => handleSourceCount("+")}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      className="h-4 w-4 mx-2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </button>
                </div>
              </div>
              <div className="w-full sm:w-1/2">
                {!currentUser && (
                  <div>
                    <form
                      action="#"
                      method="POST"
                      className="mx-auto max-w-xl "
                    >
                      <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
                        <div className="sm:col-span-2">
                          <label
                            htmlFor="email"
                            className="block text-sm/6 font-semibold text-gray-900"
                          >
                            Email{" "}
                            <span className="text-gray-500">(Required)</span>
                          </label>
                          <div className="mt-2.5">
                            <input
                              id="email"
                              name="email"
                              type="email"
                              autoComplete="email"
                              className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                              onChange={(e) => handleChange("email", e)}
                            />
                          </div>
                        </div>
                      </div>
                    </form>
                    <button
                      onClick={() => handleLogin()}
                      className="text-blue-500 text-sm"
                    >
                      I already have an account
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-6 flex justify-between">
        <button
          className="px-4 py-2 border border-gray-300 font-bold text-white bg-blue-500 hover:text-white hover:bg-black rounded-lg"
          onClick={() => prevStep()}
        >
          Go back
        </button>
        <button
          className="bg-blue-500 border hover:border-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          onClick={(e) => {
            handleSubmitOrder(e);
          }}
        >
          Proceed
        </button>
      </div>
    </div>
  );
}

export default FormSectionTwo;
