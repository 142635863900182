import React, { useEffect, useState } from "react";
import "react-phone-input-2/lib/style.css";
import { useNavigate } from "react-router-dom";

function ProfileSettings({ handleAccountUpdate, currentUser }) {
  const navigate = useNavigate();

  const [openChangePassword, setOpenChangePassword] = useState(false)
  const [username, setUsername] = useState("");
  const [phone_number, setPhoneNumber] = useState("");
  const [image, setImage] = useState("");
  const [email, setEmail] = useState("");
  const [bio, setBio] = useState(currentUser.bio);
  const [file, setFile] = useState(null);

  useEffect(() => {
    setUsername(currentUser.username);
    setPhoneNumber(currentUser.phone_number);
    setImage(currentUser.image);

    setEmail(currentUser.email);
  }, []);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFile(file);
    if (file) {
      setImage({
        url: URL.createObjectURL(file),
        name: file.name,
        preview: ["jpg", "jpeg", "png", "gif"].includes(
          file.name.split(".").pop().toLowerCase()
        ),
        size:
          file.size > 1024
            ? file.size > 1048576
              ? Math.round(file.size / 1048576) + " MB"
              : Math.round(file.size / 1024) + " KB"
            : file.size + " B",
      });
    }
  };

  //!updates account
  function handleSubmitUserForm(e) {
    e.preventDefault();
    const formData = new FormData();

    formData.append("username", username);
    formData.append("email", email);
    formData.append("image", file);
    formData.append("bio", bio);

    handleAccountUpdate(formData, currentUser.id);
  }

  const handleCancelForm = () => {
    navigate(-1);
    setUsername("");
    setBio("");
    setImage("");
  };

  const handleRemoveImage = () => {
    setImage(null);
  };

  const handleOpenChangePassword = (e) => {
    e.preventDefault()
    setOpenChangePassword((prevVal)=> !prevVal)
  }

  return (
    <div className="container mx-auto px-8 pb-12">
      <form>
        <div className="space-y-12">
          <div className="border-b border-gray-900/10 pb-12">
            <h2 className="text-base/7 font-semibold text-gray-900">Profile</h2>

            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-4">
                <label
                  htmlFor="username"
                  className="block text-sm/6 font-medium text-gray-900"
                >
                  Username
                </label>
                <div className="mt-2">
                  <div className="flex rounded-md shadow ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                    <input
                      id="username"
                      name="username"
                      type="text"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      autoComplete="username"
                      className="block flex-1 rounded-lg border  py-1.5 pl-2 text-gray-900 sm:text-sm/6 capitalize"
                    />
                  </div>
                </div>
              </div>
              <div className="sm:col-span-4">
                <label
                  htmlFor="username"
                  className="block text-sm/6 font-medium text-gray-900"
                >
                  Email
                </label>
                <div className="mt-2">
                  <div className="flex rounded-md shadow ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      autoComplete="email"
                      className="block flex-1 rounded-lg border  py-1.5 pl-2 text-gray-900 sm:text-sm/6 capitalize"
                    />
                  </div>
                </div>
              </div>

              <div className="col-span-full">
                <label
                  htmlFor="about"
                  className="block text-sm/6 font-medium text-gray-900"
                >
                  Bio
                </label>
                <div className="mt-2">
                  <textarea
                    id="about"
                    name="about"
                    rows={3}
                    value={bio}
                    onChange={(e) => setBio(e.target.value)}
                    className="block w-full rounded-md border-0 py-1.5 pl-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                  />
                </div>
                <p className="mt-3 text-sm/6 text-gray-600">
                  Write a few sentences about yourself.
                </p>
              </div>

              <div className="col-span-full">
                <label
                  htmlFor="photo"
                  className="block text-sm/6 font-medium text-gray-900"
                >
                  Photo
                </label>
                <div className="mt-2 flex items-center gap-x-3">
                  <div className="icons flex text-gray-500 m-2 space-x-4 items-start">
                    <label className="cursor-pointer flex items-center">
                      <svg
                        className="mr-2 hover:text-gray-700 border rounded-full p-1 h-7"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13"
                        />
                      </svg>
                      <input
                        hidden
                        type="file"
                        accept="image/*"
                        onChange={handleFileChange}
                      />
                      <span className="text-sm">Upload Image</span>
                    </label>

                    {/* Image Preview */}
                    {image && 
                      <div className="mt-4 flex space-x-4">
                        {image.preview && 
                          <img
                            src={image.url}
                            alt={image.name}
                            className="h-20 w-20 object-cover rounded-full mb-2"
                          />
                        }
                        <div>
                          <div className="text-xs text-gray-500 truncate">
                            {image.name} {image.size}
                          </div>
                          <button
                            className="mt-2 px-4 py-1 bg-red-500 text-white text-xs rounded"
                            onClick={handleRemoveImage}
                          >
                            Remove
                          </button>
                        </div>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className=" sm:w-3/4 rounded-md red">
            <ul className="divide-y divide-gray-300">
              {/* Change Account Password Section */}
              <li>
                <div className="flex flex-col">
                  {/* Title and Button */}
                  <div className="flex justify-between items-center">
                    <span className="px-6 py-6 whitespace-nowrap text-sm font-bold">
                      Change Account Password
                    </span>
                    <span className="px-6 py-4 whitespace-nowrap text-end text-sm font-medium">
                      <button
                        className="border border-red-500 px-2 py-1.5 text-sm font-semibold rounded-lg text-red-600 hover:text-white bg-transparent hover:bg-red-500"
                        onClick={(e) => handleOpenChangePassword(e)}
                      >
                        {openChangePassword ? "cancel" : "Change"}
                      </button>
                    </span>
                  </div>
                  {/* Password Change Form */}
                  {openChangePassword && (
                    <div className=" ps-8 my-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-12">
                      <div className="sm:col-span-4">
                        <label
                          htmlFor="password"
                          className="block text-sm font-medium text-gray-900"
                        >
                          Password
                        </label>
                        <div className="mt-2">
                          <input
                            id="password"
                            name="password"
                            type="password"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
                          />
                        </div>
                      </div>

                      <div className="sm:col-span-4">
                        <label
                          htmlFor="password-confirmation"
                          className="block text-sm font-medium text-gray-900"
                        >
                          Password Confirmation
                        </label>
                        <div className="mt-2">
                          <input
                            id="password-confirmation"
                            name="password-confirmation"
                            type="password"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </li>

              {/* Deactivate Account Section */}
              <li>
                <div className="flex justify-between items-center">
                  <span className="px-6 py-6 whitespace-nowrap text-sm font-bold">
                    Deactivate Account
                  </span>
                  <span className="px-6 py-4 whitespace-nowrap text-end text-sm font-medium">
                    <p className="text-sm font-semibold  disabled cursor-pointer text-gray-500">
                      Deactivate
                    </p>
                  </span>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div className="mt-6 flex items-center justify-end gap-x-6">
          <button
            className="text-sm/6 font-semibold text-gray-900"
            onClick={handleCancelForm}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={(e) => handleSubmitUserForm(e)}
          >
            Save
          </button>
        </div>
      </form>
    </div>
  );
}

export default ProfileSettings;
