import React from "react";
import { useNavigate } from "react-router-dom";

function ContentManagement({ resources}) {
  const navigate = useNavigate();

  return (
    <>
      <section className="bg-white w-full px-8 py-2 ">
        <div className="items-center justify-between md:flex">
          <div className=" w-full">
            <h2 className="mb-2 text-2xl font-semibold ">
              Resource Items
            </h2>
            <p className="text-sm font-medium text-body-color dark:text-dark-6">
              List of all resources
            </p>
          </div>
          <div>
            <button
              className="inline-flex items-center justify-center whitespace-nowrap rounded bg-blue-500 px-5 py-[10px] text-sm font-medium text-white hover:bg-opacity-90"
              onClick={() => navigate("/dashboard/content/newResource")}
            >
              Add New Item
              <span className="pl-2">
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M8.99961 2.39961C5.35453 2.39961 2.39961 5.35453 2.39961 8.99961C2.39961 12.6447 5.35453 15.5996 8.99961 15.5996C12.6447 15.5996 15.5996 12.6447 15.5996 8.99961C15.5996 5.35453 12.6447 2.39961 8.99961 2.39961ZM0.599609 8.99961C0.599609 4.36042 4.36042 0.599609 8.99961 0.599609C13.6388 0.599609 17.3996 4.36042 17.3996 8.99961C17.3996 13.6388 13.6388 17.3996 8.99961 17.3996C4.36042 17.3996 0.599609 13.6388 0.599609 8.99961Z"
                    fill="white"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M8.99961 5.09961C9.49667 5.09961 9.89961 5.50255 9.89961 5.99961V11.9996C9.89961 12.4967 9.49667 12.8996 8.99961 12.8996C8.50255 12.8996 8.09961 12.4967 8.09961 11.9996V5.99961C8.09961 5.50255 8.50255 5.09961 8.99961 5.09961Z"
                    fill="white"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M5.09961 8.99961C5.09961 8.50255 5.50255 8.09961 5.99961 8.09961H11.9996C12.4967 8.09961 12.8996 8.50255 12.8996 8.99961C12.8996 9.49667 12.4967 9.89961 11.9996 9.89961H5.99961C5.50255 9.89961 5.09961 9.49667 5.09961 8.99961Z"
                    fill="white"
                  />
                </svg>
              </span>
            </button>
          </div>
        </div>
      </section>
      <div className="container mx-auto p-8 ">
        <div className="h-full flex items-center justify-center">
          <div className="grid grid-cols-12 gap-2 gap-y-4 max-w-6xl red">
            <div className="col-span-12 sm:col-span-6 md:col-span-3 shadow-md bg-white">
              <card className="w-full flex flex-col">
                <div className="">
                  <a href="#">
                    <img
                      src="https://picsum.photos/seed/59/300/200"
                      className="w-96 h-auto"
                    />
                  </a>
                </div>

                <div className="flex flex-row mt-2 ps-2 gap-2">
                  <div className="flex flex-col">
                    <a href="#">
                      <p className="text-gray-800 text-sm font-semibold">
                        Learn CSS Box Model in 8 Minutes
                      </p>
                    </a>
                    <p
                      className="text-gray-600 text-xs mt-2 hover:text-gray-100"
                      href="#"
                    >
                      Web Dev Simplified
                    </p>
                    <p className="text-gray-600 text-xs my-1">
                      241K views . 3 years ago
                    </p>
                  </div>
                </div>
              </card>
            </div>
            {resources &&
              resources.map((resource) => {
                return (
                  <div className="col-span-12 sm:col-span-6 md:col-span-3 shadow-md bg-white">
                    <card className="w-full flex flex-col">
                      <div className="">
                        <a href="#">
                          <img
                            src={
                              resource.cover_image?.length
                                ? resource.cover_image
                                : "https://picsum.photos/seed/59/300/200"
                            }
                            className="w-96 h-auto"
                          />
                        </a>
                      </div>

                      <div className="flex flex-row mt-2 ps-2 gap-2">
                        <div className="flex flex-col">
                          <a href="#">
                            <p className="text-gray-800 text-sm font-semibold">
                              {resource.subject}
                            </p>
                          </a>
                          <p
                            className="text-gray-600 text-xs mt-2 hover:text-gray-100"
                            href="#"
                          >
                            {resource.content_type}
                          </p>
                          <p className="text-gray-600 text-xs my-1">
                            241K views . 3 years ago
                          </p>
                        </div>
                      </div>
                    </card>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
}

export default ContentManagement;
