import React from "react";
import students from "../assets/images/students.png";

function CourseCard() {
  return (
    <div className="p-2 bg-white shadow-lg rounded-lg border ">
      <img
        className="w-full h-44 object-cover rounded-t-lg"
        src={students}
        alt="Title"
      />
      <div className="pt-2">
        <div className="flex flex-row gap-1">
          <p className="basis-2/5 ">
            <div className="flex items-center gap-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="size-4"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M2.25 12.75V12A2.25 2.25 0 0 1 4.5 9.75h15A2.25 2.25 0 0 1 21.75 12v.75m-8.69-6.44-2.12-2.12a1.5 1.5 0 0 0-1.061-.44H4.5A2.25 2.25 0 0 0 2.25 6v12a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9a2.25 2.25 0 0 0-2.25-2.25h-5.379a1.5 1.5 0 0 1-1.06-.44Z"
                />
              </svg>

              <small>15 lessons</small>
            </div>
          </p>
          <p className="basis-3/5 ">
            <div className="flex items-center gap-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="size-4"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                />
              </svg>

              <small>7 hours 30 minutes</small>
            </div>
          </p>
        </div>
        <h4 className="font-bold text-lg">Humanity</h4>
        <p className="text-gray-600">( 3 Reviews )</p>
        <div className="flex justify-between items-center mt-4">
          <button className="border border-black text-red-600 px-4 py-2 rounded-lg">
            <div className="flex items-center gap-1">
              Enroll Now
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="size-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3"
                />
              </svg>
            </div>
          </button>
          <p className="text-lg font-bold text-red-600">Free</p>
        </div>
      </div>
    </div>
  );
}

export default CourseCard;
