import React, { createContext } from "react";

export const UrlContext = createContext();

export const UrlProvider = ({ children }) => {
  // const apiUrl = "http://localhost:3000";
  const apiUrl = "https://knowledgequest-backend.onrender.com";

  return <UrlContext.Provider value={apiUrl}>{children}</UrlContext.Provider>;
};
