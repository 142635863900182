import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

function OrderSummary({ currentUser, resources }) {

    const navigate = useNavigate()

    const { resource_id } = useParams();
    const [resource, setResource] = useState(null)

    useEffect(() => {
      localStorage.setItem(`resource_id`, resource_id);
    }, [resource_id]);

    useEffect(() => {
      const savedID = localStorage.getItem(`resource_id`);
      if (savedID) {
        const id = parseInt(resource_id.split(":")[1]);
        const filtered = resources.find((value) => value.id === id);
        setResource(filtered);
      }
    }, [resources]);
    
    const trimmedContent = resource?.content_details
      ? resource.content_details.substring(0, 100) + " ..."
      : "";

  return (
    <div className="px-8 h-screen pt-20">
      <div className="grid sm:px-10 lg:grid-cols-2 lg:px-20 xl:px-32">
        <div className="px-4 pt-8">
          <button
            className="border px-2 py-1 text-white bg-black rounded-md"
            onClick={()=>navigate(-1)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="size-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18"
              />
            </svg>
          </button>
          <p className="text-xl font-medium">Order Summary</p>
          <p className="text-gray-400">
            Check your items. And select a suitable shipping method.
          </p>
          <div className="mt-8 space-y-3 rounded-lg border bg-white px-2 py-4 sm:px-6 ">
            <div className="flex flex-col rounded-lg bg-white sm:flex-row">
              <img
                className="m-2 h-24 w-28 rounded-md border object-cover object-center"
                src={
                  resource?.cover_image?.length
                    ? resource.cover_image
                    : "https://picsum.photos/seed/59/300/200"
                }
                alt=""
              />
              <div className="flex w-full flex-col px-4 py-4">
                <span className="font-semibold">{resource?.subject}</span>
                <span className="float-right text-gray-400">
                  {resource?.page_count}{" "}
                  {resource?.page_count > 1 ? "pages" : "page"}
                </span>
                <p className="text-lg font-bold">${resource?.amount}</p>
              </div>
            </div>
          </div>

          <p className="mt-8 text-lg font-medium">Resource details</p>
          <div className="flex flex-col gap-2 mt-2 w-full ">
            <p className="m-0">
              <span className="text-gray-500 font-light pr-3">Type:</span>
              {resource?.content_type}
            </p>
            <p className="m-0">
              <span className="text-gray-500 font-light pr-3">Discipline:</span>
              {resource?.subject}
            </p>

            <p className="m-0">
              <span className="text-gray-500 font-light pr-3">
                Single spaced:
              </span>
              {resource?.single_spaced}
            </p>
            <p className="m-0">
              <span className="text-gray-500 font-light pr-3">
                Source count:
              </span>
              {resource?.source_count}
            </p>
            <p className="m-0 w-full">
              <span className="text-gray-500 font-light pr-3">
                Content details:
              </span>
              {trimmedContent}
            </p>
          </div>
        </div>
        <div className="mt-10 bg-gray-50 px-4 pt-8 lg:mt-0">
          <p className="text-xl font-medium">Finalize</p>
          <p className="text-gray-400">
            Complete your order by proceeding to checkout.
          </p>
          <div className="">
            <div className="mt-6 flex items-center justify-between">
              <p className="text-sm font-medium text-gray-900">Total</p>
              <p className="text-2xl font-semibold text-gray-900">
                ${resource?.amount}
              </p>
            </div>
          </div>
          <button className="mt-4 mb-8 w-full rounded-md bg-blue-900 px-6 py-3 font-medium text-white">
            Get Access
          </button>
        </div>
      </div>
    </div>
  );
}

export default OrderSummary;
