export const topicsArr = [
  {
    value: "admission_application_essay",
    label: "Admission/Application Essay",
  },
  { value: "annotated_bibliography", label: "Annotated Bibliography" },
  { value: "argumentative_essay", label: "Argumentative Essay" },
  { value: "article_review_critique", label: "Article Review/Critique" },
  { value: "assessment", label: "Assessment" },
  { value: "book_report_review", label: "Book Report/Review" },
  { value: "business_plan", label: "Business Plan" },
  { value: "capstone_project", label: "Capstone Project" },
  { value: "case_study", label: "Case Study" },
  { value: "coursework", label: "Coursework" },
  { value: "cover_letter_writing", label: "Cover Letter Writing" },
  { value: "discussion_post", label: "Discussion Post" },
  { value: "dissertation", label: "Dissertation" },
  {
    value: "dissertation_chapter_abstract",
    label: "Dissertation Chapter - Abstract",
  },
  {
    value: "dissertation_chapter_discussion",
    label: "Dissertation Chapter - Discussion",
  },
  {
    value: "dissertation_chapter_introduction",
    label: "Dissertation Chapter - Introduction Chapter",
  },
  {
    value: "dissertation_chapter_literature_review",
    label: "Dissertation Chapter - Literature Review",
  },
  {
    value: "dissertation_chapter_methodology",
    label: "Dissertation Chapter - Methodology",
  },
  {
    value: "dissertation_chapter_results",
    label: "Dissertation Chapter - Results",
  },
  { value: "editing", label: "Editing" },
  { value: "essay", label: "Essay" },
  { value: "formatting", label: "Formatting" },
  { value: "lab_report", label: "Lab Report" },
  { value: "math_problem", label: "Math Problem" },
  { value: "movie_review", label: "Movie Review" },
  { value: "multiple_choice_questions", label: "Multiple Choice Questions" },
  { value: "non_word_assignment", label: "Non-word assignment" },
  { value: "other", label: "Other" },
  { value: "outline", label: "Outline" },
  { value: "paraphrasing", label: "Paraphrasing" },
  { value: "personal_statement", label: "Personal Statement" },
  { value: "poster_map", label: "Poster / Map" },
  { value: "ppt_plain", label: "PowerPoint Presentation Plain" },
  {
    value: "ppt_with_speaker_notes",
    label: "PowerPoint Presentation with Speaker Notes",
  },
  { value: "proofreading", label: "Proofreading" },
  { value: "reaction_paper", label: "Reaction paper" },
  { value: "report", label: "Report" },
  { value: "research_paper", label: "Research Paper" },
  { value: "research_proposal", label: "Research Proposal" },
  { value: "resume_editing", label: "Resume Editing" },
  { value: "resume_writing", label: "Resume Writing" },
  { value: "retyping", label: "Retyping (PDF / PNG / Handwriting to Word)" },
  { value: "rewriting", label: "Rewriting" },
  { value: "scholarship_essay", label: "Scholarship Essay" },
  { value: "speech", label: "Speech" },
  { value: "statistics_project", label: "Statistics Project" },
  { value: "summary", label: "Summary" },
  { value: "term_paper", label: "Term Paper" },
  { value: "thesis", label: "Thesis" },
  {
    value: "topic_suggestion_summary_references",
    label: "Topic Suggestion + Summary + References",
  },
  { value: "thesis_proposal", label: "Thesis Proposal" },
  { value: "topic_suggestion", label: "Topic Suggestion" },
];
