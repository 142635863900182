import React from 'react'
import banner from "../../assets/images/banner.png"

function StudentIndex({ orders, currentUser, greeting, toggleSidebar }) {
  const formatDate = (value) => {
    if (!value) {
      return;
    }
    const date = new Date(value);
    const formattedDate = date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
    return formattedDate;
  };

  return (
    <>
      <div className="w-full overflow-hidden  bg-black ">
        <div className="relative h-48">
          <div className=" absolute text-white left-5 top-5">
            <div className="flex justify-start gap-6">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="size-8"
                onClick={() => toggleSidebar()}
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25H12"
                />
              </svg>
              <h1 className="text-4xl font-semibold mb-2">Dashboard</h1>
            </div>
          </div>
          <div className="px-6 py-4 text-white absolute bottom-0 w-full">
            <div className="flex gap-4 justify-between items-end">
              <div className="flex gap-4 justify-center items-end">
                <img
                  className="w-[70px] h-[70px] rounded-full object-cover"
                  src={banner}
                  alt="Card "
                />
                <div>
                  <p className="font-thin">{greeting},</p>
                  <h2 className="font-bold text-xl capitalize">
                    {currentUser.username}
                  </h2>
                  <p className="font-normal">{currentUser.role}</p>
                </div>
              </div>
              <button className="border shadow rounded-xl px-3 py-2 font-bold">
                View Profile
              </button>
            </div>
          </div>
          <img
            className="w-full h-[150px] object-cover rounded-tl-3xl"
            src={banner}
            alt="Card "
          />
        </div>

        <div className="">
          <div className="border">
            <div className="grid grid-cols-3 bg-gray-100 h-[10vh] border-gray-300 divide-x divide-gray-300">
              <div className="flex items-center justify-center ">
                <p>12 Resources</p>
              </div>
              <div className="flex items-center justify-center ">
                <p>12 Notifications</p>
              </div>
              <div className="flex items-center justify-center ">
                <p>12 Messages</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-12 ">
        <div className="col-span-8 py-4">
          <div className="flex justify-between items-middle px-6">
            <p className="font-bold">Orders</p>
            <p className="">Sort by</p>
          </div>
          <ul
            role="list"
            className="divide-y divide-gray-100 px-8 overflow-y-scroll h-[50vh]"
          >
            {orders.map((order) => {
              return (
                <li
                  key={order.id}
                  className="flex justify-between  gap-x-6 py-5"
                >
                  <div className="flex justify-between items-center min-w-0 gap-x-4 w-full">
                    <div className="min-w-0 flex-auto">
                      <div className="inline-flex gap-8">
                        <h2 className="font-bold">{order.order_type}</h2>
                        <span
                          className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset ${
                            order.order_status === "processing"
                              ? "bg-blue-50 text-blue-600 ring-blue-500/10"
                              : order.order_status === "complete"
                              ? "bg-green-50 text-green-600 ring-green-500/10"
                              : order.order_status === "pending"
                              ? "bg-purple-50 text-purple-600 ring-purple-500/10"
                              : order.order_status === "cancelled"
                              ? "bg-red-50 text-red-600 ring-red-500/10"
                              : "bg-gray-50 text-gray-600 ring-gray-500/10" // Fallback for any unknown status
                          }`}
                        >
                          {order.order_status}
                        </span>
                      </div>
                      <p className="text-sm">
                        Due on {formatDate(order.deadline)}
                      </p>
                    </div>
                    <div>
                      <button className="shadow font-medium border px-2 py-1 rounded-lg">
                        View order
                      </button>
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="col-span-4 py-4 border bg-gray-100">
          <div className="flex justify-between items-middle px-8">
            <p className="font-bold">Activity feed</p>
            <p className="">View all </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default StudentIndex