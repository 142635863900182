import React,{ useEffect, useState } from "react";

function TimePassed({ value }) {
  const [timePassed, setTimePassed] = useState("")

  useEffect(() => {
    const now = new Date();
    const past = new Date(value.created_at);
    console.log("TIME", past);

    const diffInMilliseconds = now - past;
    const diffInSeconds = Math.floor(diffInMilliseconds / 1000);
    const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));
    const diffInHours = Math.floor(diffInMilliseconds / (1000 * 60 * 60));
    const diffInDays = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24));
    const diffInWeeks = Math.floor(diffInDays / 7);
    const diffInMonths = Math.floor(diffInDays / 30); // Approximation for months

    let res
    if (diffInSeconds < 60) {
      res = `${diffInSeconds} ${diffInSeconds !== 1 ? "s" : ""} ago`;
    } else if (diffInMinutes < 60) {
      res = `${diffInMinutes} ${diffInMinutes !== 1 ? "m" : ""} ago`;
    } else if (diffInHours < 24) {
      res = `${diffInHours} hr${diffInHours !== 1 ? "s" : ""} ago`;
    } else if (diffInDays === 1) {
      res = "Yesterday";
    } else if (diffInWeeks < 1) {
      res = `${diffInDays} day${diffInDays !== 1 ? "s" : ""} ago`;
    } else if (diffInMonths < 1) {
      res = `${diffInWeeks} wk${diffInWeeks !== 1 ? "s" : ""} ago`;
    } else {
      res = `${diffInMonths} M${diffInMonths !== 1 ? "s" : ""} ago`;
    }
    setTimePassed(res)
  }, [value]);

  return (
    <p
      tabindex="0"
      className="focus:outline-none text-xs leading-3 pt-1 text-gray-500"
    >{timePassed}</p>
  );
}

export default TimePassed;