import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import profile from "../../assets/images/profile.jpg";
import { NavLink } from "react-router-dom";

function UsersList({ allUsers, handleAccountUpdate }) {
  const [filterByName, setFilterByName] = useState("");
  const [filterByRole, setFilterByRole] = useState("");
  const [openUser, setOpenUser] = useState(false);
  const [selectedUser, setSelectedUser] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [role, setRole] = useState("");

  const handleCheckboxChange = () => {
    setIsChecked((prevVal) => !prevVal);
  };

  useEffect(() => {
    setIsChecked(selectedUser.status);
    setRole(selectedUser.role);
  }, [selectedUser])

  const users_columns = [
    {
      name: "User",
      selector: (row) => (
        <div className="d-flex justify-content-between gap-2 align-items-center ">
          <div style={{ height: "6vh", width: "3vw" }}>
            <img
              className=" h-full w-full rounded-full"
              src={row.image.length ? row.image : profile}
              alt="info"
            />
          </div>
          <div>
            <ul className=" list-unstyled mb-0">
              <li style={{ fontWeight: "bold" }} className="text-capitalize">
                {row.username}
              </li>
              <li>{row.email}</li>
            </ul>
          </div>
        </div>
      ),
    },
    {
      name: "Role",
      selector: (row) => <span className="text-capitalize">{row.role}</span>,
      sortable: true,
      reorder: true,
    },

    {
      name: "Status",
      selector: (row) => (
        <>
          <span
            className={`inline-flex items-center rounded-full px-2 py-1 text-xs font-medium ring-1 ring-inset ${
              row.status === true
                ? "bg-blue-50 text-blue-600 ring-blue-500/10"
                : row.status === false
                ? "bg-green-50 text-green-600 ring-green-500/10"
                : "bg-gray-50 text-gray-600 ring-gray-500/10"
            }`}
          >
            {row.status === true ? "Active" : "Inactive"}
          </span>
        </>
      ),
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
      reorder: true,
    },
    {
      name: "Edit",
      selector: (row) => (
        <>
          <button onClick={() => handleOpenUser(row)}>
            <span className="inline-flex items-center text-xs font-medium">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="size-6"
                onChange={(e) => setRole(e.target.value)}
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                />
              </svg>
            </span>
          </button>
        </>
      ),
    },
  ];

  const handleOpenUser = (value) => {
    setOpenUser((prevVal) => !prevVal);
    setSelectedUser(value);
  };

  const handleUpdateUser = (e) => {
    e.preventDefault();

    const formData = new FormData();

    formData.append("role", role);
    formData.append("status", isChecked);
    handleAccountUpdate(formData, selectedUser.id);
    handleOpenUser()
  };

  return (
    <div className="container mx-auto">
      <div className="flex flex-col items-center space-y-4 px-8">
        <div className="w-full">
          <div className="card text-start bg-transparent border-none">
            <div className="card-body">
              <div className="flex justify-between items-center space-x-4 p-4">
                <div className="text-start">
                  <h4 className="m-0 text-lg font-semibold">Users</h4>
                  <p className="m-0 text-sm text-gray-600">A list of users.</p>
                </div>
                <div>
                  <NavLink to="/catalog/create">
                    <button
                      type="button"
                      className="btn bg-green-500 text-white hover:bg-green-600 py-2 px-4 rounded"
                    >
                      Add User
                    </button>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full">
          <div className="card bg-white shadow-lg">
            <div className="card-body">
              <div className="flex justify-end items-center space-x-4">
                <div className="w-1/4">
                  <input
                    className=" rounded h-10 px-4 py-2 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    placeholder="Filter"
                    type="text"
                    onChange={(e) => setFilterByName(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full">
          <div className="card border-none bg-white shadow-lg">
            <div className="card-body">
              <DataTable
                columns={users_columns}
                data={allUsers}
                fixedHeader
                pagination
                className="custom-data-table"
              />
            </div>
          </div>
        </div>
        <div className="w-full">
          {openUser && (
            <>
              <div className="overlay">
                <div className="max-w-md w-full space-y-8 p-10 bg-white rounded-xl shadow-lg z-10">
                  <div className="grid gap-8 grid-cols-1">
                    <div className="flex flex-col">
                      <div className="flex flex-col sm:flex-row items-center">
                        <h2 className="font-semibold text-lg mr-auto">
                          Edit user info
                        </h2>
                      </div>
                      <div className="mt-5">
                        <form
                          className="form"
                          onSubmit={(e) => handleUpdateUser(e)}
                        >
                          <div className="md:space-y-2 mb-3">
                            <label className="font-bold capitalize text-gray-600 py-2">
                              {selectedUser.username}
                              <abbr className="hidden" title="required">
                                *
                              </abbr>
                            </label>
                            <div className="flex items-center">
                              <div className="w-12 h-12 mr-4 flex-none rounded-xl border overflow-hidden">
                                <img
                                  className="w-12 h-12 object-cover"
                                  src={
                                    selectedUser.image.length
                                      ? selectedUser.image
                                      : profile
                                  }
                                  alt="Avatar Upload"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="md:flex flex-row md:space-x-4 w-full text-xs">
                            <div className="mb-3 space-y-1 w-full flex flex-col">
                              <label className="font-semibold text-gray-600 py-2">
                                Status
                              </label>
                              <label className="autoSaverSwitch relative inline-flex cursor-pointer select-none items-center">
                                <input
                                  type="checkbox"
                                  name="autoSaver"
                                  className="sr-only"
                                  checked={isChecked}
                                  onChange={handleCheckboxChange}
                                />
                                <span
                                  className={`slider mr-3 flex h-[26px] w-[50px] items-center rounded-full p-1 duration-200 ${
                                    isChecked ? "bg-blue-500" : "bg-[#CCCCCE]"
                                  }`}
                                >
                                  <span
                                    className={`dot h-[18px] w-[18px] rounded-full bg-white duration-200 ${
                                      isChecked ? "translate-x-6" : ""
                                    }`}
                                  ></span>
                                </span>
                                <span className="label flex items-center text-sm font-medium text-black">
                                  <span className="pl-1">
                                    {isChecked ? "Active" : "Inactive"}
                                  </span>
                                </span>
                              </label>
                            </div>
                          </div>

                          <div className="mb-4 space-y-2 w-full">
                            <label className="font-semibold text-gray-600 py-2">
                              Role
                            </label>
                            <div className="flex items-stretch w-full relative">
                              <select
                                name="role"
                                required
                                className="block w-full h-10 px-4 rounded-lg border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                onChange={(e) => setRole(e.target.value)}
                              >
                                <option value="">Select role</option>
                                <option value="tutor">Tutor</option>
                                <option value="student">Student</option>
                              </select>
                            </div>
                          </div>
                          <div className="mt-5 flex justify-end space-x-3">
                            <button
                              className="bg-white px-5 py-2 text-sm shadow-sm font-medium text-gray-600 border rounded-full hover:bg-gray-100"
                              onClick={() => handleOpenUser("")}
                            >
                              Cancel
                            </button>
                            <button
                              type="submit"
                              className="bg-green-400 px-5 py-2 text-sm shadow-sm font-medium text-white rounded-full hover:bg-green-500"
                            >
                              Save
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default UsersList;
