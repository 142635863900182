import ActionCable from "actioncable";
import React from "react";

const CableContext = React.createContext();

function CableProvider({ children }) {
   const actionCableUrl =
     process.env.NODE_ENV === "production"
       ? "wss://knowledgequest-backend.onrender.com/cable"
       : "ws://localhost:3000/cable";
  const CableApp = {};
  CableApp.cable = ActionCable.createConsumer(actionCableUrl);

  return (
    <CableContext.Provider value={CableApp.cable}>
      {children}
    </CableContext.Provider>
  );
}

export { CableContext, CableProvider };
