import React from "react";
import lecturer from "../assets/images/lecturer.png";
import graduated from "../assets/images/graduated.png";
import student from "../assets/images/student.jpg";
import ribbon from "../assets/images/ribbon.png";
import students from "../assets/images/students.png";
import computerScience from "../assets/images/computerScience.png";
import medicine from "../assets/images/medicine.png";
import accounts from "../assets/images/accounts.png";

import why2 from "../assets/images/why-2.png";
import why3 from "../assets/images/why-3.png";
import why4 from "../assets/images/why-4.png";
import why5 from "../assets/images/why-5.png";
import book from "../assets/images/book.png";
import timer from "../assets/images/timer.png";
import expert from "../assets/images/expert.png";
import stamp from "../assets/images/stamp.png";
import process1 from "../assets/images/process1.png";
import process2 from "../assets/images/process2.png";
import process3 from "../assets/images/process3.png";
import { motion } from "framer-motion";
import CourseCard from "../components/CourseCard";
import Footer from "../components/Footer";
import { useNavigate } from "react-router-dom";
import category_bg from "../assets/images/category_bg.png";

function Home({ currentUser }) {
  const navigate = useNavigate();

  const links = [
    { name: "Explore courses ", href: "#" },
    { name: "Our programs", href: "#" },
    { name: "Student success stories", href: "#" },
    { name: "Meet your mentors", href: "#" },
  ];
  const stats = [
    { name: "Students worldwide", value: "12,00+" },
    { name: "Projects completed", value: "300+" },
    { name: "Hours of tutoring", value: "4,000+" },
    { name: "Free Resources", value: "Unlimited" },
  ];

  return (
    <div className="mt-8">
      <main>
        <section>
          <div className="md:container md:mx-auto lg:px-24 px-4">
            <div className="grid xl:grid-cols-2 py-12 space gap-8">
              <div className=" xl:order-2 flex justify-center items-center">
                <div className="relative w-full max-w-md">
                  <img
                    src={lecturer}
                    alt="landing page"
                    className="w-full object-contain"
                  />
                  <div className="absolute bottom-40 max-w-sm max-h-md space-y-2 p-3 shadow-lg rounded  bg-white border">
                    <img
                      className="w-full h-12 object-contain"
                      src={graduated}
                      alt="student icon"
                    />
                    <div>
                      <p class="font-bold text-md lg:text-xl">
                        Students <br /> Enrolls
                      </p>
                      <p class="text-tundora-700 font-bold text-3xl">12K+</p>
                    </div>
                  </div>
                  <div className="absolute left-25 bottom-5 max-w-md max-h-sm space-x-2 p-2 shadow-lg rounded bg-white border flex items-center">
                    <p class="text-tundora-700 font-bold text-sm">50+ Tutors</p>
                    <div>
                      <div className="flex -space-x-1 overflow-hidden">
                        <img
                          alt=""
                          src="https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                          className="inline-block h-8 w-8 lg:h-12 lg:w-12 rounded-full ring-2 ring-white"
                        />
                        <img
                          alt=""
                          src="https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                          className="inline-block h-8 w-8 lg:h-12 lg:w-12 rounded-full ring-2 ring-white"
                        />
                        <img
                          alt=""
                          src="https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=256&h=256&q=80"
                          className="inline-block h-8 w-8 lg:h-12 lg:w-12 rounded-full ring-2 ring-white"
                        />
                        <img
                          alt=""
                          src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                          className="inline-block h-8 w-8 lg:h-12 lg:w-12 rounded-full ring-2 ring-white"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="xl:order-1 my-auto">
                <h1 className="text-4xl sm:text-5xl font-extrabold pb-2 text-sapphire-950">
                  The Ideal Path to{" "}
                  <span className="text-yellow-500 capitalize underline decoration-yellow-300">
                    Elevate
                  </span>{" "}
                  Your Learning
                </h1>
                <p className="mb-4 text-tundora-600">
                  Empower students with tailored learning, fostering growth
                  through personalized support and seamless, real-time
                  educational connections.
                </p>

                {currentUser?.role !== "tutor" &&
                  currentUser?.role !== "admin" && (
                    <button
                      type="button"
                      className="rounded-md bg-sapphire-800 px-6 py-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      onClick={() => navigate("/order")}
                    >
                      Get Started
                    </button>
                  )}
              </div>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 md:gap-8 gap-4 mb-4">
              <div className="w-full rounded shadow-lg bg-white m-auto py-2">
                <div className="flex align-center ">
                  <img
                    className="basis-1/4 w-full h-16 object-contain p-1"
                    src={book}
                    alt="library books"
                  />
                  <div className="basis-3/4 flex items-center">
                    <div>
                      <h2 className="text-xl font-bold">
                        Book Library & Store
                      </h2>
                      <button className="rounded p-2 flex gap-2 items-center">
                        View more
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full rounded shadow-lg bg-white m-auto py-2">
                <div className="flex">
                  <img
                    className="basis-1/4 w-full h-16 object-contain p-1"
                    src={timer}
                    alt="library books"
                  />
                  <div className="basis-3/4 flex items-center">
                    <div>
                      <h2 className="text-xl font-bold">10K+ Online Courses</h2>
                      <button className="rounded p-2 flex gap-2 items-center">
                        View more
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full rounded shadow-lg bg-white m-auto py-2">
                <div className="flex">
                  <img
                    className="basis-1/4 w-full h-16 object-contain p-1"
                    src={expert}
                    alt="library books"
                  />
                  <div className="basis-3/4 flex items-center">
                    <div>
                      <h2 className="text-xl font-bold">Expert Instructor</h2>
                      <button className="rounded p-2 flex gap-2 items-center">
                        View more
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-12 min-h-screen pt-4">
              <div className="relative col-span-12 md:col-span-7 flex justify-center md:justify-start">
                <div className="max-w-sm h-3/4 rounded-lg shadow-md bg-gray-100">
                  <img
                    className="w-full h-full rounded object-cover"
                    src={student}
                    alt="school"
                  />
                </div>
                <div className="absolute md:inset-0 left-0 md:left-72 top-10 md:top-28 z-30 bg-transparent">
                  <div className="rounded-lg w-28 h-28 md:w-44 md:h-44">
                    <img
                      className="w-full h-full rounded object-cover"
                      src={stamp}
                      alt="sealing stamp"
                    />
                  </div>
                </div>
                <div className="absolute bottom-10 md:bottom-20 max-w-lg rounded-tr-lg rounded-br-lg rounded-bl-lg shadow-xl bg-white p-4 md:p-0">
                  <div className="flex items-center gap-4 md:px-4 md:py-2">
                    <img
                      className="h-10 md:h-16 object-contain p-1"
                      src={ribbon}
                      alt="student icon"
                    />
                    <div>
                      <h2 className="font-bold text-xl md:text-4xl">10+</h2>
                      <p className="text-gray-700 text-base">
                        Years of Education
                      </p>
                    </div>
                  </div>
                </div>
                <div className="hidden lg:flex absolute h-60 w-60 md:h-90 md:w-80 bottom-0 md:bottom-20 right-4 md:right-16 border-4 border-white bg-white rounded-lg">
                  <img
                    className="w-full h-full object-cover rounded-lg"
                    src={students}
                    alt="students discussing"
                  />
                </div>
              </div>
              <div className="col-span-12 md:col-span-5 p-4 md:p-0">
                <h1 className="font-bold text-xl md:text-2xl mb-5">About Us</h1>
                <h2 className="text-lg sm:text-2xl md:text-3xl font-bold mb-2.5">
                  Our Mission is to empower learners by providing{" "}
                  <span className="underline capitalize">personalized</span>,
                  accessible, and effective online tutoring
                </h2>
                <p className="text-gray-700 mb-2.5">
                  We are driven by the belief that education has the power to
                  shape a brighter future, and we are committed to making a
                  positive impact on the lives of our students, helping them
                  achieve their full potential.
                </p>
                <button className="rounded p-2 bg-gray-500 text-white">
                  <div className="flex gap-2 items-center">
                    More about us
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3"
                      />
                    </svg>
                  </div>
                </button>
              </div>
            </div>
            <div className="hidden xl:block">
              <div className="relative grid grid-cols-12 h-96 w-full max-h-[400px] rounded-2xl">
                <img
                  alt=""
                  src={category_bg}
                  className="absolute inset-0 -z-1 h-full w-full object-cover object-right md:object-center rounded-2xl"
                />
                <div className="flex items-start col-span-8 md:col-span-6 md:items-center justify-center ps-4 xl:ps-0 pt-4 xl:pt-0 z-10">
                  <div>
                    <h3 className="font-bold text-xl mb-3 text-yellow-400">
                      Categories
                    </h3>
                    <h2 className="font-bold text-4xl text-white mb-2">
                      Featured Topics by Category
                    </h2>
                    <button className="rounded p-2 bg-yellow-500 text-black">
                      <div className="flex gap-2">
                        All Categories
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="size-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3"
                          />
                        </svg>
                      </div>
                    </button>
                  </div>
                </div>
                <div className="relative flex justify-center items-center col-span-4 md:col-span-6">
                  <div className="max-w-sm rounded shadow-lg  bg-white h-60 w-40 ">
                    <img
                      className="w-full h-20 mt-2 object-contain"
                      src={computerScience}
                      alt="computer science topic"
                    />
                    <div class="px-6 py-4  text-center">
                      <h2 class="font-bold text-xl mb-2">Computer Science</h2>
                      <p class="text-gray-700 text-base">08 Cources</p>
                      <button type="button" className="py-3 ">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="size-6 bg-yellow-500 rounded-xl"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M12 4.5v15m7.5-7.5h-15"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                  <div className="absolute right-14 bottom-52 max-w-sm rounded shadow-lg  bg-white h-60 w-40 ">
                    <img
                      className="w-full h-20 mt-2 object-contain"
                      src={medicine}
                      alt="computer science topic"
                    />
                    <div class="px-6 py-4  text-center">
                      <h2 class="font-bold text-xl mb-2">Medicine</h2>
                      <p class="text-gray-700 text-base">08 Cources</p>
                      <button type="button" className="py-3 ">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="size-6 bg-yellow-500 rounded-xl"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M12 4.5v15m7.5-7.5h-15"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                  <div className="absolute right-14 top-52 max-w-sm rounded shadow-lg  bg-white h-60 w-40 ">
                    <img
                      className="w-full h-20 mt-2 object-contain"
                      src={accounts}
                      alt="computer science topic"
                    />
                    <div class="px-6 py-4  text-center">
                      <h2 class="font-bold text-xl mb-2">Accounts</h2>
                      <p class="text-gray-700 text-base">08 Cources</p>
                      <button type="button" className="py-3 ">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="size-6 bg-yellow-500 rounded-xl"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M12 4.5v15m7.5-7.5h-15"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <p className="p-4">
                We’ve 37800 Satisfied customers with our services.
              </p>
            </div>
          </div>
        </section>
        <section>
          <div className="md:container md:mx-auto sm:px-24 py-24">
            <div className="text-center">
              <h3 className="font-bold text-xl">Courses</h3>
              <h2 className="font-bold text-3xl">Most Popular Course</h2>
            </div>
            <div className=" grid grid-cols-12  gap-3 p-4 md:p-0 mt-6">
              <div className="col-span-12 md:col-span-6 lg:col-span-4 max-w-md">
                <CourseCard />
              </div>
              <div className="col-span-12 md:col-span-6 lg:col-span-4 max-w-md">
                <CourseCard />
              </div>
              <div className="col-span-12 md:col-span-6 lg:col-span-4 max-w-md">
                <CourseCard />
              </div>
            </div>
            <div className=" grid grid-cols-2 gap-3 p-4 md:p-0 mt-6">
              <CourseCard />
              <CourseCard />
            </div>
          </div>
        </section>
        <section>
          <div className="md:container md:mx-auto sm:px-24  mb-16">
            <div className="container mx-auto">
              <div className="flex flex-col lg:flex-row justify-center items-center gap-8">
                <div className="lg:w-1/2 w-full flex flex-col  items-center text-start">
                  <div className="w-full text-start">
                    <motion.h2
                      className="about text-3xl font-bold mb-4"
                      initial={{ opacity: 0, y: 100 }}
                      whileInView={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.93 }}
                      viewport={{ once: true }}
                    >
                      <span>Why Choose us</span>
                    </motion.h2>
                    <motion.p
                      className="about_mission text-4xl leading-tight mb-2"
                      initial={{ opacity: 0, y: 100 }}
                      whileInView={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.9 }}
                      viewport={{ once: true }}
                    >
                      Find More About Us E-learning{" "}
                      <span className=" text-yellow-500">Experience</span>
                    </motion.p>
                  </div>
                  <motion.div
                    className="card-body flex justify-center p-2 w-3/4   transform -rotate-8 bg-pink-200 shadow"
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    transition={{ duration: 0.7 }}
                    viewport={{ once: true }}
                  >
                    <img
                      className="w-full h-full object-cover  transform -rotate-6"
                      src={students}
                      alt="Title"
                    />
                  </motion.div>
                </div>

                <div className="lg:w-1/2 w-full grid grid-cols-1 sm:grid-cols-2 gap-6">
                  <motion.div
                    className="flex flex-col items-center"
                    initial={{ opacity: 0, y: 100 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                    viewport={{ once: true }}
                  >
                    <div className="bg-white rounded-lg shadow-md p-4 w-full flex flex-col items-start">
                      <img
                        className="h-10 w-10 mb-4"
                        src={why2}
                        alt="Flexible Learning"
                      />
                      <h4 className="text-xl font-bold mb-2">
                        Flexible Learning
                      </h4>
                      <p className="text-gray-600">
                        Providing multiple means of engagement, representation,
                        and expression for all students to learn.
                      </p>
                    </div>
                  </motion.div>

                  <motion.div
                    className="flex flex-col items-center"
                    initial={{ opacity: 0, y: 100 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.9 }}
                    viewport={{ once: true }}
                  >
                    <div className="bg-white rounded-lg shadow-md p-4 w-full flex flex-col items-start">
                      <img
                        className="h-10 w-10 mb-4"
                        src={why3}
                        alt="Learn Anything"
                      />
                      <h4 className="text-xl font-bold mb-2">Learn Anything</h4>
                      <p className="text-gray-600">
                        Providing multiple means of engagement, representation,
                        and expression for all students to learn.
                      </p>
                    </div>
                  </motion.div>

                  <motion.div
                    className="flex flex-col items-center"
                    initial={{ opacity: 0, y: 100 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8 }}
                    viewport={{ once: true }}
                  >
                    <div className="bg-white rounded-lg shadow-md p-4 w-full flex flex-col items-start">
                      <img
                        className="h-10 w-10 mb-4"
                        src={why4}
                        alt="Competitive Pricing"
                      />
                      <h4 className="text-xl font-bold mb-2">
                        Competitive Pricing
                      </h4>
                      <p className="text-gray-600">
                        Providing multiple means of engagement, representation,
                        and expression for all students to learn.
                      </p>
                    </div>
                  </motion.div>

                  <motion.div
                    className="flex flex-col items-center"
                    initial={{ opacity: 0, y: 100 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 1.0 }}
                    viewport={{ once: true }}
                  >
                    <div className="bg-white rounded-lg shadow-md p-4 w-full flex flex-col items-start">
                      <img
                        className="h-10 w-10 mb-4"
                        src={why5}
                        alt="Lifetime Access"
                      />
                      <h4 className="text-xl font-bold mb-2">
                        Lifetime Access
                      </h4>
                      <p className="text-gray-600">
                        Providing multiple means of engagement, representation,
                        and expression for all students to learn.
                      </p>
                    </div>
                  </motion.div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="relative isolate overflow-hidden bg-gray-900 py-24 sm:py-32">
            <div className="md:container md:mx-auto sm:px-24 py-24">
              <img
                alt=""
                src="https://images.unsplash.com/photo-1521737604893-d14cc237f11d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&crop=focalpoint&fp-y=.8&w=2830&h=1500&q=80&blend=111827&sat=-100&exp=15&blend-mode=multiply"
                className="absolute inset-0 -z-10 h-full w-full object-cover object-right md:object-center"
              />
              <div
                aria-hidden="true"
                className="hidden sm:absolute sm:-top-10 sm:right-1/2 sm:-z-10 sm:mr-10 sm:block sm:transform-gpu sm:blur-3xl"
              >
                <div
                  style={{
                    clipPath:
                      "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
                  }}
                  className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
                />
              </div>
              <div
                aria-hidden="true"
                className="absolute -top-52 left-1/2 -z-10 -translate-x-1/2 transform-gpu blur-3xl sm:top-[-28rem] sm:ml-16 sm:translate-x-0 sm:transform-gpu"
              >
                <div
                  style={{
                    clipPath:
                      "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
                  }}
                  className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
                />
              </div>
              <div className="mx-auto max-w-7xl">
                <div className="mx-auto max-w-2xl lg:mx-0">
                  <h2 className="text-5xl font-semibold tracking-tight text-white sm:text-7xl">
                    Study with us
                  </h2>
                  <p className="mt-8 text-pretty text-lg font-medium text-gray-300 sm:text-xl/8">
                    Unlock your potential with our dedicated tutoring team! Our
                    platform connects you with experienced educators who will
                    inspire, support, and guide you to success in every subject.
                  </p>
                </div>
                <div className="mx-auto mt-10 max-w-2xl lg:mx-0 lg:max-w-none">
                  <div className="grid grid-cols-1 gap-x-8 gap-y-6 text-base/7 font-semibold text-white sm:grid-cols-2 md:flex lg:gap-x-10">
                    {links.map((link) => (
                      <a key={link.name} href={link.href}>
                        {link.name} <span aria-hidden="true">&rarr;</span>
                      </a>
                    ))}
                  </div>
                  <dl className="mt-16 grid grid-cols-1 gap-8 sm:mt-20 sm:grid-cols-2 lg:grid-cols-4">
                    {stats.map((stat) => (
                      <div
                        key={stat.name}
                        className="flex flex-col-reverse gap-1"
                      >
                        <dt className="text-base/7 text-gray-300">
                          {stat.name}
                        </dt>
                        <dd className="text-4xl font-semibold tracking-tight text-white">
                          {stat.value}
                        </dd>
                      </div>
                    ))}
                  </dl>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="md:container md:mx-auto sm:px-24 py-24 ">
            <p className="text-center">How it works</p>
            <h2 className="font-bold text-3xl text-center">
              Check How We Work in Easy Steps
            </h2>
          </div>

          <div className="flex flex-wrap justify-center gap-4 m-0">
            {/* First Step */}
            <motion.div
              className="lg:w-1/4 md:w-1/4 w-1/2  text-center  flex flex-col items-center justify-center"
              initial={{ opacity: 0, y: 100 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.73 }}
              viewport={{ once: true }}
            >
              <div className="relative h-48 w-48 ">
                <img
                  className="w-full h-auto object-cover"
                  src={process1}
                  alt="info"
                />
                <p
                  className="step rounded-full text-xl leading-none"
                  style={{
                    padding: "5px",
                    position: "absolute",
                    top: "12%",
                    right: "15%",
                    outline: "2px dotted #FFB704",
                    outlineOffset: "1.5px",
                    backgroundColor: "#FFB704",
                  }}
                >
                  01
                </p>
              </div>
              <p className="font-bold">Send an Order</p>
              <p>Ask any type of question you want</p>
            </motion.div>

            {/* Second Step */}
            <motion.div
              className="lg:w-1/4 md:w-1/4 w-1/2 py-3 text-center flex flex-col items-center justify-center"
              initial={{ opacity: 0, y: 100 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.73 }}
              viewport={{ once: true }}
            >
              <div className="relative h-48 w-48">
                <img className="w-full h-auto" src={process2} alt="info" />
                <p
                  className="step rounded-full text-xl leading-none"
                  style={{
                    padding: "5px",
                    position: "absolute",
                    top: "12%",
                    right: "15%",
                    outline: "2px dotted #FFB704",
                    outlineOffset: "1.5px",
                    backgroundColor: "#FFB704",
                  }}
                >
                  02
                </p>
              </div>
              <p className="font-bold">Connect with Tutor</p>
              <p>
                Our system recommends tutors specialized in that discipline.
              </p>
            </motion.div>

            {/* Third Step */}
            <motion.div
              className="lg:w-1/4 md:w-1/4 w-1/2 py-3 text-center flex flex-col items-center justify-center"
              initial={{ opacity: 0, y: 100 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.73 }}
              viewport={{ once: true }}
            >
              <div className="relative h-48 w-48">
                <img className="w-full h-auto" src={process3} alt="info" />
                <p
                  className="step rounded-full text-xl leading-none"
                  style={{
                    padding: "5px",
                    position: "absolute",
                    top: "12%",
                    right: "15%",
                    outline: "2px dotted #FFB704",
                    outlineOffset: "1.5px",
                    backgroundColor: "#FFB704",
                  }}
                >
                  03
                </p>
              </div>
              <p className="font-bold">Response</p>
              <p>You receive quality explanations & answers.</p>
            </motion.div>
          </div>
        </section>
        <section>
          <div className="md:container md:mx-auto sm:px-24 py-24 ">
            <p className="text-center font-bold">Testimonial</p>
            <h3 className="font-bold text-3xl text-center ">
              Review's From Students{" "}
            </h3>
          </div>
        </section>
        <section>
          <div className="md:container md:mx-auto sm:px-24 py-24 ">
            <div>
              <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <h2 className="text-center text-lg/8 font-semibold text-gray-900">
                  Trusted by the world’s most innovative teams
                </h2>
                <div className="mx-auto mt-10 grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-5">
                  <img
                    alt="Transistor"
                    src="https://tailwindui.com/plus/img/logos/158x48/transistor-logo-gray-900.svg"
                    width={158}
                    height={48}
                    className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
                  />
                  <img
                    alt="Reform"
                    src="https://tailwindui.com/plus/img/logos/158x48/reform-logo-gray-900.svg"
                    width={158}
                    height={48}
                    className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
                  />
                  <img
                    alt="Tuple"
                    src="https://tailwindui.com/plus/img/logos/158x48/tuple-logo-gray-900.svg"
                    width={158}
                    height={48}
                    className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
                  />
                  <img
                    alt="SavvyCal"
                    src="https://tailwindui.com/plus/img/logos/158x48/savvycal-logo-gray-900.svg"
                    width={158}
                    height={48}
                    className="col-span-2 max-h-12 w-full object-contain sm:col-start-2 lg:col-span-1"
                  />
                  <img
                    alt="Statamic"
                    src="https://tailwindui.com/plus/img/logos/158x48/statamic-logo-gray-900.svg"
                    width={158}
                    height={48}
                    className="col-span-2 col-start-2 max-h-12 w-full object-contain sm:col-start-auto lg:col-span-1"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <footer>
        <Footer />
      </footer>
    </div>
  );
}

export default Home;
