import React from "react";
import profile from "../../assets/images/profile.jpg";
import { useNavigate } from "react-router-dom";

function TutorsProfile({ tasks, currentUser, reviews }) {
  const pendingTaskCount = tasks
    ? tasks.filter((task) => task.order_status === "processing").length
    : 0;
const completeTaskCount = tasks
  ? tasks.filter((task) => task.order_status === "complete").length
  : 0;

  const navigate = useNavigate();

  return (
    <div className="container mx-auto ">
      <div className="flex flex-col">
        <div className=" grid grid-cols-12 gap-8 shadow  bg-green-100 rounded-lg p-6">
          <img
            src={currentUser.image.length !== 0 ? currentUser.image : profile}
            alt="profile"
            className="w-32 h-32 rounded-full mb-4 lg:col-span-2 col-span-4"
          />
          <div className="lg:col-span-4 col-span-8 ">
            <h1 className="text-2xl font-bold">{currentUser.username}</h1>
            <p className="text-sm text-gray-600">{currentUser.bio}</p>
            <p className="mt-4 text-sm">
              Experience: {currentUser.experience} years
            </p>
            <p className="text-sm">Languages: {currentUser?.subjects}</p>
            <p className="text-sm">Status: {currentUser?.availability}</p>
          </div>
          <div className=" lg:col-span-6 col-span-12 text-end  flex justify-end items-end">
            <div className="mt-4">
              <button
                type="button"
                className="px-4 py-2 bg-white text-gray-800 font-semibold rounded-lg shadow"
                onClick={() => navigate("/dashboard/tutor_settings")}
              >
                <div className="flex flex-row gap-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="size-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M11.42 15.17 17.25 21A2.652 2.652 0 0 0 21 17.25l-5.877-5.877M11.42 15.17l2.496-3.03c.317-.384.74-.626 1.208-.766M11.42 15.17l-4.655 5.653a2.548 2.548 0 1 1-3.586-3.586l6.837-5.63m5.108-.233c.55-.164 1.163-.188 1.743-.14a4.5 4.5 0 0 0 4.486-6.336l-3.276 3.277a3.004 3.004 0 0 1-2.25-2.25l3.276-3.276a4.5 4.5 0 0 0-6.336 4.486c.091 1.076-.071 2.264-.904 2.95l-.102.085m-1.745 1.437L5.909 7.5H4.5L2.25 3.75l1.5-1.5L7.5 4.5v1.409l4.26 4.26m-1.745 1.437 1.745-1.437m6.615 8.206L15.75 15.75M4.867 19.125h.008v.008h-.008v-.008Z"
                    />
                  </svg>
                  <span>Settings</span>
                </div>
              </button>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-4">
          {/* Stats Section */}
          <div className="space-y-2">
            <h2 className="text-xl font-bold mb-4">Stats</h2>
            <div className="space-y-4">
              <div className="grid grid-cols-2 gap-4 ">
                <div className="p-4 bg-yellow-300 text-gray-900 rounded-lg">
                  <div className="font-bold text-3xl">{completeTaskCount}</div>
                  <div className="mt-2">Tasks Completed</div>
                </div>
                <div
                  className="p-4 bg-yellow-300 text-gray-900 rounded-lg"
                  onClick={() => navigate("/dashboard/tasks")}
                >
                  <div className="font-bold text-3xl">{pendingTaskCount}</div>
                  <div className="mt-2">Pending tasks</div>
                </div>
              </div>

              <div className="p-4 bg-purple-200 rounded-lg">
                <div className="font-bold text-lg">Your daily plan</div>
                <div className="mt-2">5 of 8 completed</div>
              </div>
            </div>
          </div>

          {/* Your Tasks Today Section */}
          <div className="space-y-2">
            <h2 className="text-xl font-bold mb-4">Your tasks today</h2>
            <div className="space-y-4">
              {tasks &&
                tasks.map((data) => {
                  return (
                    <div className="p-4 bg-white border rounded-lg">
                      <div className="flex justify-between text-gray-500 text-sm">
                        <div>Number 10</div>
                        <div>4h</div>
                      </div>
                      <div className="mt-2 inline-flex gap-8">
                        <p
                          className="m-0 p-0 font-bold text-gray-900 cursor-pointer"
                          style={{ outline: "none", boxShadow: "none" }}
                          onClick={() =>
                            navigate(`/dashboard/tasks/:${data.id}`)
                          }
                        >
                          {data.order_type}
                        </p>
                        <span
                          className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset ${
                            data.order_status === "processing"
                              ? "bg-blue-50 text-blue-600 ring-blue-500/10"
                              : data.order_status === "complete"
                              ? "bg-green-50 text-green-600 ring-green-500/10"
                              : data.order_status === "pending"
                              ? "bg-purple-50 text-purple-600 ring-purple-500/10"
                              : data.order_status === "cancelled"
                              ? "bg-red-50 text-red-600 ring-red-500/10"
                              : "bg-gray-50 text-gray-600 ring-gray-500/10" // Fallback for any unknown status
                          }`}
                        >
                          {data.order_status}
                        </span>
                      </div>
                      <div className="text-sm text-gray-500 flex items-center mt-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="1em"
                          height="1em"
                          fill="currentColor"
                          className="text-gray-900 mr-2"
                          viewBox="0 0 16 16"
                        >
                          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
                        </svg>
                        Deadline is today
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
        <div className="mt-4">
          <h2 className="text-lg font-bold">Reviews</h2>
          {reviews &&
            reviews.map((review) => (
              <div key={review.id} className="p-2 border-b">
                <p className="font-semibold">{review.studentName}</p>
                <p className="text-sm text-gray-600">{review.comment}</p>
                <p className="text-sm">Rating: {review.rating}/5</p>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

export default TutorsProfile;
